export const API_URL = "https://lifefit.appgrowthcompany.com/api/v1/Admin/";

// export const media_url="https://lifefit.appgrowthcompany.com/api/v1//Upload/"

export const END_POINTS = {
  login: "login",
  mediaUpload: "upload",
  get_user: "profile",
  logout: "logout",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "setPassword",
  changePassword: "changePassword",
  

  // ////profile
  updateProfile: "updateProfile",
   getProfile:"getProfile",


   //manage-users
  getUsers:"user",
  changeUserStatus:'changeStatus',
  exportUsers:'userExports',


  //dashboard
  dashboard:"adminDashboard",

   //cms
   cms:"cms",


   //recipee
   recipe:"recipe",
   recipeByDiet:"recipeByDiet",
   //diet
   diet:"diet",
  

   //fasting
   fasting:"fasting",
   
   //biohacking
  biohacking:"biohacking",   

  //workout category
  workoutCategory:"workoutCategory",

  //workout videos

  workoutVideo:"workout",

  //subscription feature
  feature:"feature",

  //subscription
  subscription:"subscriptionPlan",

  //questionaire
  survey:"nutritionTracker",

  //analytics
  userGraph:"userGraph",
  totalRevenuGraph:"totalRevenuGraph",
  totalSubscriptionGraph:"totalSubscriptionGraph",
  
  //subadmin roles
  subAdminRoles:"subAdminRoles",
    
  //subadmin
  subAdmin:"subAdmin",

  //exports
  subscriptionExports:"subscriptionExports",
  earningExports:"earningExports",

  //notifications
  notification:"notification",

  //recieved notification
  adminNotification:"adminNotification",

  exercise:"workoutRoutine",

  trainingPlan:"trainingPlan",
};
