import { Routes, Route, redirect, createBrowserRouter, createRoutesFromElements, RouterProvider,Outlet, useNavigate } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";
import MainContainer from "./layout/MainContainer";
import { PageNotFound } from "./features";
import useAuth from "./hooks/useAuth";
import { getFromStorage, STORAGE_KEYS } from "./helpers";
import { useAppSelector } from "./hooks/store";
import { getCurrentUser } from "./reducers/authSlice";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "./utils/firebase";


const Routing = () => {


  // const user=useAuth();
  // const role=parseInt(secureLocalStorage.getItem("role")||'0');
  // const permissions=secureLocalStorage?.getItem("permissions")!=="undefined"&&JSON.parse(secureLocalStorage?.getItem("permissions")||"")?.[0].isView;
  // console.log("roleeee",role);
  // console.log("ffffffffffff",permissions)
  // console.log("sssssssssss",data);
  
  const router=createBrowserRouter(createRoutesFromElements(
    <Route path="/">
        <Route index element={<Pages.LoginPage />} />
        <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
        <Route path="verifyotp" element={<Pages.VerifyOtp />} />
        <Route path="resetpassword" element={<Pages.ResetPassword />} />
        <Route path="changepassword" element={<Pages.ChangePassword />} />
        <Route element={<MainContainer/>}>

        <Route index path="dashboard" element={<Pages.DashBoardPage />} />
        <Route path="profile" element={<Pages.Profile />} />
        
        <Route path="manage-users"
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[0].isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        >
         
        <Route index element={<Pages.ManageUsers />} />
        <Route path="details/:id" element={<UserDetails />} />
        <Route path="usersform/:id" element={<UsersForm />} 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[0]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        </Route>


        <Route path="analytics" element={<Pages.Analytics />} 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
          return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[8]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        <Route path="manage-survey-questionnaries" element={<Pages.ManageSurveyQuestionnaries />} 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[6]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        
        <Route path="cms" element={<Pages.ManageCms />} 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[9]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        
        <Route path="manage-notifications"
        loader={async()=>{
         if(secureLocalStorage.getItem("role")=='1')
           return null;
         if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[7]?.isView)
           {
             throw redirect('/dashboard');
           }
           return null;
       }}
       errorElement={<Pages.DashBoardPage/>}
        >
        <Route index element={<Pages.ManageNotifications />}/>
        <Route path="add" element={<Pages.AddNotification />} 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[7]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        </Route>


        <Route path="recieved-notifications" element={<Pages.RecievedNotifications />} 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[7]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
       
        <Route path="manage-subadmin" 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")!='1')
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        >
        <Route index element={<Pages.ManageSubAdmin />}/>
        <Route path="add" element={<Pages.AddSubAdmin />} />
        <Route path="edit/:id" element={<Pages.AddSubAdmin />} />
        </Route>


        <Route path="manage-exercises"
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[10]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}>
        <Route index element={<Pages.ManageExercises />} />
        <Route path="add" element={<Pages.AddCategories />} 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[10]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}/>
        <Route path="edit/:id" element={<Pages.AddCategories />} 
          loader={async()=>{
            if(secureLocalStorage.getItem("role")=='1')
              return null;
            if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[10]?.isEdit)
              {
                throw redirect('/dashboard');
              }
              return null;
          }}
          errorElement={<Pages.DashBoardPage/>}
          />
        <Route path="details/:id" element={<Pages.DetailsExercises />} />
        </Route>
    
      
        {/* <Route path="manage-categories/details" element={<Pages.SubCategories />}/> */}
          


        <Route path="manage-training-plan"
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[11]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        >
          <Route index element={<Pages.ManageTrainingPlan/>}/>
          <Route path="add" element={<Pages.AddTrainingPlan/>}
           loader={async()=>{
            if(secureLocalStorage.getItem("role")=='1')
              return null;
            if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[11]?.isEdit)
              {
                throw redirect('/dashboard');
              }
              return null;
          }}
          errorElement={<Pages.DashBoardPage/>}
          />
          <Route path="edit/:id" element={<Pages.AddTrainingPlan/>}
           loader={async()=>{
            if(secureLocalStorage.getItem("role")=='1')
              return null;
            if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[11]?.isEdit)
              {
                throw redirect('/dashboard');
              }
              return null;
          }}
          errorElement={<Pages.DashBoardPage/>}
          />
          <Route path="details/:id" element={<Pages.TrainingPlandetails/>}/>

        </Route>


        <Route path="manage-recipes"
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[1]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        >
        <Route index element={<Pages.ManageRecipes />} />

        
        <Route path="add" element={<Pages.AddRecipes />}
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[1]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        

        <Route path="edit/:id" element={<Pages.AddRecipes />} 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[1]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        <Route path="details/:id" element={<Pages.RecipesDetails />}/>
        </Route>

          {/* body scan */}
         {/* <Route path="manage-bodyScan" >
         <Route index element={<Pages.MnnageBodyScan />} />
         <Route path="add" element={<Pages.AddBodyScan />} />
         <Route path="details" element={<Pages.ManageBodyScanDetails />} />
          </Route> */}
          
         {/* fitness tracker */}
         {/* <Route path="manage-fitness-tracker">
         <Route index element={<Pages.ManageFitnessTracker />} />
         <Route path="add" element={<Pages.AddFitnessTracker />} />
         <Route path="details" element={<Pages.ManageFitnessTrackerDetails />} />
         </Route> */}

         {/* bioHacking details */}
         <Route path="manage-bio-hacking" 
          loader={async()=>{
            if(secureLocalStorage.getItem("role")=='1')
              return null;
            if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[3]?.isView)
              {
                throw redirect('/dashboard');
              }
              return null;
          }}
          errorElement={<Pages.DashBoardPage/>}
          >
         <Route index element={<Pages.MnnageBioHacking />} />
         <Route path="add" element={<Pages.AddBioHacking />} 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[3]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        />
         <Route path="edit/:id" element={<Pages.AddBioHacking />} 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions")  as string||"")?.[3]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
         <Route path="details/:id" element={<Pages.BioHackingDetails />} />
         </Route>
         
        {/* fasting */}
        <Route path="manage-fasting" 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[4]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        >
        <Route index element={<Pages.ManageFasting />} />
        <Route path="add" element={<Pages.AddFasting />} 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[4]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        <Route path="edit/:id" element={<Pages.AddFasting />} 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[4]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        <Route path="details/:id" element={<Pages.FastingDetails />}/>
        </Route>
  
        <Route path="manage-subscription" 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[5]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        >
        <Route index element={<Pages.ManageSubscription />} />
        <Route path="add" element={<Pages.Addsubscription />} 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[5]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        <Route path="edit/:id" element={<Pages.Addsubscription />} 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[5]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        />
        <Route path="details/:id" element={<Pages.SubscriptionDetails />} />
        </Route>

        <Route path="workout-videos-management" 
         loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[2]?.isView)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        >
        <Route index element={<Pages.WorkoutVideosManagement />} />
        <Route path="add" element={<Pages.AddWorkoutVideos />} 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[2]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        errorElement={<Pages.DashBoardPage/>}
        />
        <Route path="edit/:id" element={<Pages.AddWorkoutVideos />} 
        loader={async()=>{
          if(secureLocalStorage.getItem("role")=='1')
            return null;
          if(secureLocalStorage?.getItem("permissions")!=="undefined"&&!JSON.parse(secureLocalStorage?.getItem("permissions") as string||"")?.[2]?.isEdit)
            {
              throw redirect('/dashboard');
            }
            return null;
        }}
        />
        <Route path="details/:id" element={<Pages.WorkoutVideoDetails />} />
        </Route>

        {/* <Route path="workout-routines-management" >
        <Route index element={<Pages.WorkoutRoutineManagement />} />
        <Route path="add" element={<Pages.AddWorkoutRoutine />} />
        <Route path="details" element={<Pages.WorkoutRoutinesDetails />} />
        </Route> */}

        <Route path="*" element={<PageNotFound/>}/>
      </Route>
    </Route>

  ))
  return (
    <RouterProvider router={router}/>
  );
};

export default Routing;
