import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Parser from 'html-react-parser';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetFastingsByIdQuery } from "../../services/fasting";
import { showError } from "../../constants/toast";
import { FastingResponse } from "../../types/fasting";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FastingDetails = () => {
  const navigate = useNavigate();
  const {id}=useParams();
  const[fastingData,setFastingData]=useState<FastingResponse>();
  const[getFastingById]=useLazyGetFastingsByIdQuery();

  const getData=async(id:string)=>{
    try{
    const response=await getFastingById({id:id}).unwrap();
    if(response.statusCode==200)
    {
    setFastingData(response?.data);
    }
    
    }
    catch(error:any)
    {
    showError(error?.data?.message || "");
    }}
    useEffect(()=>{
      if(id)  
      getData(id);
    
    },[])
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
  
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Fasting Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-fasting");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                
              <Grid item lg={2} md={2} sm={6} xs={12}>
              <Typography component="h5">Image</Typography>
                <figure className="profile_img">
                  <img src={fastingData?.image?fastingData?.image:"/static/images/fasting-placeholder.png"} alt="" />
                </figure>
              </Grid>
                <Grid container spacing={3}>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    {/* <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Name
                    </Typography> */}
                    <Grid container spacing={3}>
                     
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Title</Typography>
                          <Typography component="p">{fastingData?.title}</Typography>
                        </Box>
                      </Grid>

                   
                      <Grid item xs={12}>
  <Box>
    <Typography component="h5">Description</Typography>
    
      <Typography  component="p" sx={{ alignItems: 'center' }}>
        {fastingData?.description?.trim()=="<p></p>"?"No Description":(fastingData?.description && Parser(fastingData?.description))}
        
      </Typography>
   
   
  </Box>
</Grid>


                     
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

 
      </div>
 
  );
};

export default FastingDetails;
