//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getUserGraph:builder.query<
CommonResponseType & {data:any},
    string>({
      
        // query:({page,limit,query})=>{
        query:(type)=>{
          let url = END_POINTS.userGraph;
        const queryParams = [];
        if(type)
        {
           queryParams.push(`type=${type}`)
        }
        // if (page) {
        //   queryParams.push(`page=${page}`);
        // }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),
getEarningGraph:builder.query<
CommonResponseType & {data:any},
    string>({
      
        // query:({page,limit,query})=>{
        query:(type)=>{
          let url = END_POINTS.totalRevenuGraph;
        const queryParams = [];
        if(type)
        {
           queryParams.push(`type=${type}`)
        }
        // if (page) {
        //   queryParams.push(`page=${page}`);
        // }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),
getSubscriptionGraph:builder.query<
CommonResponseType & {data:any},
    string>({
      
        // query:({page,limit,query})=>{
        query:(type)=>{
          let url = END_POINTS.totalSubscriptionGraph;
        const queryParams = [];
        if(type)
        {
           queryParams.push(`type=${type}`)
        }
        // if (page) {
        //   queryParams.push(`page=${page}`);
        // }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),
getUsersCSV: builder.query<CommonResponseType & { data: string }, null>({
  query: () => {
    let url = END_POINTS.exportUsers;
   return{ 
    url:url,
    method: "GET",}
  },
}),
getEarningsCSV: builder.query<CommonResponseType & { data: string },null>({
  query: () => {
    let url = END_POINTS.earningExports;
   return{ 
    url:url,
    method: "GET",}
  },
}),
getSubscriptionsCSV: builder.query<CommonResponseType & { data: string },null>({
  query: () => {
    let url = END_POINTS.subscriptionExports;
   return{ 
    url:url,
    method: "GET",}
  },
}),
}),

})

export const{
    useLazyGetEarningGraphQuery,
    useLazyGetSubscriptionGraphQuery,
    useLazyGetUserGraphQuery,
    useLazyGetEarningsCSVQuery,
    useLazyGetSubscriptionsCSVQuery,
    useLazyGetUsersCSVQuery
}=userApi;
