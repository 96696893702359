import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteAdminNotificationByIdMutation, useDeleteAdminNotificationsMutation, useLazyGetAdminNotificationQuery } from "../../services/notificationRecieve";
import { showError, showToast } from "../../constants/toast";
import { isValidInput } from "../../utils/validations";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { ClearAll, Delete, RemoveCircle } from "@mui/icons-material";
import { Pagination } from "../../components";
import { generateResponsiveStyle } from "../../utils/modalStyle";
import useAuth from "../../hooks/useAuth";

const RecievedNotifications = () => {
  const style = generateResponsiveStyle();
  const[adminNotification,setAdminNotification]=useState<any>([]);
  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const[open2,setOpen2]=useState(false);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const user = useAuth();

  function createData(
    CustomerName: string,
    CustomerUsername: string,
    CustomerEmail: string,
    title: string,
    message: string
  ) {
    return { CustomerName, CustomerUsername, CustomerEmail, title, message };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "abdulhussian67@gmail.com",
      "Title",
      "Message"
    ),
  ];
  const[getAdminNotification]=useLazyGetAdminNotificationQuery();
  const[deleteById]=useDeleteAdminNotificationByIdMutation();
  const[deleteAll]=useDeleteAdminNotificationsMutation()
  const handleDeleteAll=async()=>{
   
    try{
      const response=await deleteAll(null).unwrap();
      if(response.statusCode==200)
        {
          showToast("All notifications deleted successfully")
          getAdminNotificationList();
        }
    }
    catch(error:any)
    {
      showError(error?.data?.message);
    }
  }
  const getAdminNotificationList = async () => {
    setIsLoading(true);
    try {
      const response = await getAdminNotification({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAdminNotification(response?.data?.data || []);
        console.log(response?.data);
        setTotalCount(response?.data?.count);
      } else {
        setAdminNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    //   console.log("TESTING")
        getAdminNotificationList();
      }, [debouncedSearchTerm, page]);
  return (

      <div className="main_loyout">
        <div className="dashboard">
          <h1>Recieved Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
             <Box className="cards_header_right">
              {(user?.role==1)||(user?.permissions?.[7]?.isDelete)?<Button className="btn btn_primary"
              onClick={()=>{
                if(adminNotification.length==0)
                  {
                    showError("No notification to delete");
                    return;
                  }
                setOpen2(true);
              }}>
                <ClearAll /> Clear All
              </Button>:null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  {(user?.role==1)||(user?.permissions?.[7]?.isDelete)?<TableCell align="center">Delete</TableCell>:null}
                </TableRow>
              </TableHead>
              <TableBody>
                {adminNotification.length!=0 ? adminNotification.map((row:any, i:number) => (
                  <TableRow>
                    <TableCell align="center">{(page-1)*10+i+1}</TableCell>
                    <TableCell>{row.senderId.fullName}</TableCell>
                    <TableCell>{row.senderId.email}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.message}</TableCell>
                      {(user?.role==1)||(user?.permissions?.[7]?.isDelete)?
                    <TableCell>
                      <Box className="table_actions">
                    
                      <IconButton
                      onClick={()=>{
                        setSelectedId(row._id);
                        setOpen(true);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                 
                      </Box>
                    </TableCell>
                    :null}
                  </TableRow>
                )):<TableRow ><TableCell colSpan={(user?.role==1)||(user?.permissions?.[7]?.isDelete)?6:5} sx={{textAlign:"center"}}>
                  No Recieved Notifications</TableCell></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={adminNotification}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        <WarnModal
        setOpen={setOpen}
        open={open}
        name={"notification"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getAdminNotificationList)}
      />
       <Modal
      open={open2}
      onClose={() => setOpen2(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontSize: "19px" }}
        >
          Are you sure you want to delete all notifications?
        </Typography>
        <div className="flexdiv">
          <Button
            className="btn btn_primary"
            onClick={() => {
              setOpen2(false);
             
              handleDeleteAll();
            }}
          >
            Yes
          </Button>

          <Button
          // style={{backgroundColor:"black"}}
          className="btn btn_primary"

            onClick={() => setOpen2(false)}
          >
            No
          </Button>
        </div>
      </Box>
    </Modal>
      </div>

  );
};

export default RecievedNotifications;
