//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import { BiohackingResponse } from "../types/biohacking";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetAllBiohackingResponse = {
    biohacking: BiohackingResponse[];
    count: number;
  };
  
type GetTokenParams = {
    // limit?: number;
    page?: number;
    query?: string;
  };
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getBiohacking:builder.query<
CommonResponseType & {data:GetAllBiohackingResponse},
    GetTokenParams>({
      
        // query:({page,limit,query})=>{
        query:({page,query})=>{
          let url = END_POINTS.biohacking;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),

getBiohackingsById:builder.query<CommonResponseType & {data:BiohackingResponse},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.biohacking}/${id}`,
        method:"GET",
    })
}),

changeBiohackingStatus: builder.query<
CommonResponseType & { data: BiohackingResponse },
{ id: string }
>({
query: ({ id }) => ({
  url: `${END_POINTS.changeUserStatus}/${id}`,
  method: "GET",
}),
}),

editBiohackingById: builder.mutation<
      CommonResponseType & { data: BiohackingResponse },
      { id: string|undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.biohacking}/${id}`,
        method: "PUT",
        body,
      }),
    }),

deleteBiohackingById:builder.mutation<
CommonResponseType & {data:BiohackingResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.biohacking}/${id}`,
        method:'DELETE'
    })
}),

addBiohacking:builder.mutation<
CommonResponseType & {data:any},
CommonBody
>({
  query:(body)=>({
    url:END_POINTS.biohacking,
    method:'POST',
    body
  })
})

})
})
export const{
 useLazyGetBiohackingQuery,
 useLazyGetBiohackingsByIdQuery,
 useEditBiohackingByIdMutation,
 useAddBiohackingMutation,
 useDeleteBiohackingByIdMutation

}=userApi;
