//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import AddSubAdmin from "../pages/manageSubAdmin/add";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllSubAdminsResponse = {
    subAdmin: never[];
    SubAdmin: any;
    count: number;
  };
  
type GetTokenParams = {
    limit?: number;
    page?: number;
    query?: string;
  };
// type EditSubAdminById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type EditSubAdminById = {
  hash: string;
  sek: string;
} | null
export const SubAdminApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getSubAdmins:builder.query<
CommonResponseType & {data:GetAllSubAdminsResponse},
    GetTokenParams>({
      
        query:({page,limit,query})=>{
          let url = END_POINTS.subAdmin;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),
getSubAdminById:builder.query<CommonResponseType & {data:any},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.subAdmin}/${id}`,
        method:"GET",
    })
}),
editSubAdminById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string; body: EditSubAdminById }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.subAdmin}/${id}`,
        method: "PUT",
        body,
      }),
    }),
deleteSubAdminById:builder.mutation<
CommonResponseType & {data:any},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.subAdmin}/${id}`,
        method:'DELETE'
    })
}),

addSubAdmin:builder.mutation<CommonResponseType & {data:any},CommonBody>({
    query:(body)=>({
        url:`${END_POINTS.subAdmin}`,
        method:'POST',
        body
    })
})
})
})

export const{
    useLazyGetSubAdminsQuery,
    useLazyGetSubAdminByIdQuery,
    useEditSubAdminByIdMutation,
    useDeleteSubAdminByIdMutation,
    useAddSubAdminMutation,
}=SubAdminApi;
