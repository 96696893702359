 
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { NutritionQuestionnaries } from "../../features";
import Recipe from "../../features/recipe/recipe";
import AddIcon from "@mui/icons-material/Add";
import DetailsExercises from "../../features/exercises/detailsExercise";
import BreakfastAddRecipeDetailed from "../../features/recipe/BreakfastrecipeDetailed";
import { useAddDietMutation, useDeleteDietByIdMutation, useEditDietByIdMutation, useLazyGetDietByIdQuery, useLazyGetDietsQuery, useLazyGetRecipeByDietQuery } from "../../services/diet";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import { CameraAlt, Cancel } from "@mui/icons-material";
import { errorToast } from "../../helpers";
import { UploadMedia } from "../../utils/uploadMedia";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { Pagination } from "../../components";
import { handleDelete } from "../../utils/commonFunctions";
import Loader from "../../helpers/Loader";
import WarnModal from "../../components/modals/WarnModal";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Role {
  name: string;
  enabled: boolean;
}
const AddRecipe = () => {
  const navigate = useNavigate();
  const{id}=useParams();
  const[selectedDietName,setSelectedDietName]=useState("");
  const [newDiet, setNewDiet] = useState("");
  const[loading,setLoading]=useState(false);
  const[currentDietName,setCurrentDietName]=useState("");
  const[currentDietImage,setCurrentDietImage]=useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [snacks, setSnacks] = useState<any>([]);
  const[selectedDiet,setSelectedDiet]=useState("");
  const [fileName, setFileName] = useState<string>("");
  const [value, setValue] = useState<number>(id?1:0);
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [nestedValue, setNestedValue] = useState<number>(0); // Add state for nested tab value
  const [dietPicture,setDietPicture]=useState("");
  // const userData = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  // const [searchTerm, setSearchTerm] = useState<string>("");
  // const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const[recipeByDiet,setRecipeByDiet]=useState([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [diets, setDiets] = useState<any>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const[imageId,setImageId]=useState<any>(null);
  const[dietId,setDietId]=useState("");
  const [getDiet, { isLoading }] = useLazyGetDietsQuery();
  const[addDiet]=useAddDietMutation();
  const [deleteById]=useDeleteDietByIdMutation();
  const [updateDietStatus]=useEditDietByIdMutation();
  const [getRecipeByDiet]=useLazyGetRecipeByDietQuery();
  const[getDietById]=useLazyGetDietByIdQuery();
  const [category, setCategory] = useState<string>("");
  
  const handleDietChange = (event: SelectChangeEvent) => {
    setDietId(event.target.value)
    const value = event.target.value;
    setSelectedDiet(value);
   };
 

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const getDietByIdData=async()=>{
    try{
      const response=await getDietById({id:id}).unwrap();
      if(response.statusCode==200)
      {
        setCurrentDietName(response.data.name);
        setCurrentDietImage(response.data.image);
        console.log("RRRRR",response.data)
      }

    }
    catch(error:any)
    {
      showError(error.data.message);
    }
  }
  useEffect(()=>{
if(id)
{
  getDietByIdData();
  setDietId(id);
  setSelectedDiet(id);
}
  },[])


  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };
  const handleDietUpdate=async()=>{
    try{
      const body={
        name:currentDietName,
        image:currentDietImage
      }
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response=await updateDietStatus({id:id,body:encryptedBody}).unwrap();
      if(response.statusCode==200)
      {
          console.log("QQQQQ",response.data);
          showToast("Diet updated successfully")
      }
    }
    catch(error:any)
    {
      showError(error.data.message);
    }
  }
  const getRecipeByDietData = async(id:any)=>{
    try{
      const response=await getRecipeByDiet({id:selectedDiet}).unwrap();
      if(response.statusCode==200)
      {
          setRecipeByDiet(response.data.recipe)
          console.log(response);
      }
    }
    catch(error:any)
    {
      showError(error.data.message);
    }
  }
useEffect(()=>{
  if(dietId)
  getRecipeByDietData(dietId);
},[dietId])
console.log("sssssssssssssssss",recipeByDiet);
  const getDietsList = async () => {
    try {
      const response = await getDiet({
        page: page,
        // limit: 10,
        // query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setDiets(response?.data?.diet || []);
        setTotalCount(response?.data?.count);
      } else {
        setDiets([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
console.log("ddddddddddddd",currentDietName);

const handleAddDiet=async (e:FormEvent)=>{
e.preventDefault();
if(dietPicture=="")
{
  showError("Please add diet image")
  return;
}
if(newDiet=="")
{
  showError("Please enter diet name")
  return;
}
if(newDiet.length>50)
  {
    showError("Diet name can't exceed 50 characters");
    return;
  }
try{
const body={
  image:dietPicture,
  name:newDiet  
}

const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
const response=await addDiet(encryptedBody).unwrap();
if(response.statusCode==200)
{
console.log(response.data);
showToast("Diet Added Successfully");
getDietsList();
setDietPicture("");
setNewDiet("");
}
}
catch(error:any)
{
showError(error.data.message);
}
}
console.log("DIET",selectedDiet);
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        isBlocked:!diets[index]?.isBlocked
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateDietStatus({ id: `${diets[index]._id}` , body:encryptedData}).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setDiets((prevData:any)=>{
        let temp=[...prevData];
        temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getDietsList();
  }, [page]);
  console.log(diets);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission
  };
const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue(newValue); // Update parent tab value
  setNestedValue(0); // Reset nested tab value to default when parent tab changes
};
  
  // const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   if (files && files[0].type.includes("video")) {
  //     setFileName(files[0].name);
  //     setVideo(URL.createObjectURL(files[0]));
  //   } else {
  //     setAlertType(0);
  //     setShowAlert(true);
  //     setAlertMessage("This field only accepts videos.");
  //   }
  // };


  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  function createData(Module: string) {
    return { Module };
  }
  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  // const rows = [
  //   createData("Manage Users"),
  //   createData("Manage Exercises"),
  //   createData("Manage Recipes"),
  //   createData("Workout Routine Mnagement"),
  //   createData("Revenue Subscription"),
  //   createData("Workout Videos Management"),
 
  //   createData("Manage Survey Questionnaries"),
 
  //   createData(" Notification Management"),
  //   createData(" Analytics"),
  //   createData("Manage CMS"),
  // ];

  
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {

        if(id)
        setCurrentDietImage("");
        setDietPicture("");
        setImageId(null);
        return;
      }
      const allowedExtensions = ["png","jpg","jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss",fileExtension);
      if (!allowedExtensions.includes(fileExtension||"")) {
        setLoading(false);
        showError("Invalid file format: only png, jpg images are allowed");
        return;
      }
      const res = await UploadMedia(file);
//      console.log(res,'lklk');
      
      if (res?.statusCode === 200) {
        if(id)
        setCurrentDietImage(res?.data);
        setDietPicture(res?.data);
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
    setLoading(false);

  };
  return (
    <>
  <Loader isLoad={loading}/>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{id?"Edit":"Add"} Diet</h1>  
  
                        
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-recipes");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
          {id &&   <form >
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {currentDietImage ? (
                      <div className="upload_image_preview3">
                        <CardMedia component="img" image={currentDietImage} sx={ {border:"2px dotted black"}} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setCurrentDietImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg,image/jpg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn3">
                            <img
                            style={{border:"1px dotted black"}}
                              src={
                                image
                                  ? image
                                  :  "/static/images/placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {/* {!currentDietImage ? (
                      <h6 className="err_msg">Image is required</h6>
                    ) : (
                      ""
                    )} */}
                  </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Rename Diet
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          fullWidth
                          placeholder="Rename Diet"
                          value={currentDietName}
                          onChange={(e)=>{
                            if(e.target.value.length<=50)
                            setCurrentDietName(e.target.value)
                            if(e.target.value.length>50)
                            showError("Diet name cant exceed 50")
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>}     
            <Box className="custom_tabs">
              <Box className="flx_sc">
                {!id && <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Add Diet" {...a11yProps(0)} />
                  <Tab label="Add Recipe" {...a11yProps(1)} />
                </Tabs>}
              </Box>
              <CustomTabPanel value={value} index={0}>
                <form >
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {dietPicture ? (
                      <div className="upload_image_preview3">
                        <CardMedia component="img" image={dietPicture} sx={ {border:"2px dotted black"}} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setDietPicture("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg,image/jpg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn3">
                            <img
                            style={{border:"1px dotted black"}}
                              src={
                                image
                                  ? image
                                  :  "/static/images/placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {/* {error && !dietPicture ? (
                      <h6 className="err_msg">Image is required</h6>
                    ) : (
                      ""
                    )} */}
                  </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Add Diet
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          fullWidth
                          placeholder="Add Diet"
                          value={newDiet}
                          onChange={(e)=>setNewDiet(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={handleAddDiet}
                      >
                        Add
                      </Button>
                    </div>
                  </CardContent>
                </form>
                

                <Grid item xs={12}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="mn_hdng">S.No.</TableCell>
                          <TableCell className="mn_hdng">Diet</TableCell>
                          <TableCell className="mn_hdng">Status</TableCell>
                          <TableCell className="mn_hdng">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      {diets?.length ? (
                        diets?.map((diet:any, i:any) => (
                          <TableBody>
                            <TableRow>
                              <TableCell className="mn_hdng">{(page - 1) * 10 + i + 1}</TableCell>
                              <TableCell>{diet.name}</TableCell>
                              <TableCell>
                              <Switch
                                {...label}
                                  size="small"
                                  checked={!diet?.isBlocked}
                                  onChange={()=>handleStatusChange(i)}
                              />
                              </TableCell>
                              <TableCell>
                                <IconButton onClick={() => {
                                  setOpen(true);
                                  setSelectedId(diet._id);
                                }}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      ) : (
                        <TableBody
                          sx={{
                            padding: "20px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <TableRow

                          // sx={{ padding: "20px", textAlign: "center" }}
                          >
                            <TableCell
                              colSpan={5}
                              sx={{ padding: "20px", textAlign: "center" }}
                            >
                              No Diet Added
                            </TableCell>{" "}
                            {/* No Roles Added */}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
                <Pagination
          module={diets}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
            {  !id && <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                      {id?"Selected Diet":"Select Diet"}
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={id?id:selectedDiet}
                        onChange={handleDietChange}
                        displayEmpty
                        // sx={{ minWidth: "250px" }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {
                          diets.map((diet:any)=>
                            <MenuItem value={diet._id} onClick={()=>setCurrentDietName(diet.name)}>{diet.name}</MenuItem>
                        
                          )
                        }
                         
                      </Select>
                    </FormControl>
                  </Grid>}
{(id||selectedDiet) && <Box>   


      
  <Tabs
  sx={{paddingBlock:"20px"}}
  value={nestedValue}
  onChange={(event, newValue) => setNestedValue(newValue)} // Update nested tab value
  variant="scrollable"
  scrollButtons="auto"
  aria-label="nested tabs example"
  className="custom_tabs_links"
>
  <Tab label={((currentDietName.toLowerCase()=="juices")||(currentDietName.toLowerCase()=="juice"))?"Milkshake":"Breakfast"} {...a11yProps(0)} />
  <Tab label={((currentDietName.toLowerCase()=="juices")||(currentDietName.toLowerCase()=="juice"))?"Smoothies":"Mains"} {...a11yProps(1)} />
  {((currentDietName.toLowerCase()=="juices")||(currentDietName.toLowerCase()=="juice"))?null:<Tab label="Snacks" {...a11yProps(2)} />}
</Tabs>

  <CustomTabPanel value={nestedValue} index={0}>
  <form onSubmit={handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  
              
  
                  
                  {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Recipe Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Recipe Name"
                    />
                  </Grid> */}
               <Recipe type={1} getRecipeByDietData={getRecipeByDietData} selectedDiet={selectedDiet} snacks={recipeByDiet} setSnacks={setRecipeByDiet}/>
                   
                      {/* Add the description text field below the video upload */}
                      {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Calorie
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Calorie"
                    />
                  </Grid>
                  
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Fat
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Fat"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Protien
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Protien"
                    />
                  </Grid> */}
                  
                 
                </Grid>
                
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={()=>{
                      if(currentDietImage=="")
                        {
                          showError("Diet image is required")
                          return;
                        }
                      if(currentDietName=="")
                        {
                          showError("Diet name is required");
                          return;
                        }
                        if(currentDietName.length>50)
                          {
                            showError("Diet name can't exceed 50 characters")
                            return;
                          }
                      if(id)
                      handleDietUpdate();
                      showToast("Diet updated successfully")
                      navigate('/manage-recipes');
                    }}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
              
            </form>
  </CustomTabPanel>
  <CustomTabPanel value={nestedValue} index={1}>
  <form onSubmit={handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  
              
  
                  
                  {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Recipe Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Recipe Name"
                    />
                  </Grid> */}
               <Recipe type={2} getRecipeByDietData={getRecipeByDietData} selectedDiet={selectedDiet} snacks={recipeByDiet} setSnacks={setRecipeByDiet}/>
                   
                      {/* Add the description text field below the video upload */}
                      {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Calorie
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Calorie"
                    />
                  </Grid>
                  
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Fat
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Fat"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Protien
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Protien"
                    />
                  </Grid> */}
                  
                 
                </Grid>
                
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={()=>{
                      if(currentDietImage=="")
                        {
                          showError("Diet image is required")
                          return;
                        }
                      if(currentDietName=="")
                        {
                          showError("Diet name is required");
                          return;
                        }
                        if(currentDietName.length>50)
                          {
                            showError("Diet name can't exceed 50 characters")
                            return;
                          }
                      if(id)
                      handleDietUpdate();
                      showToast("Diet updated successfully")
                      navigate('/manage-recipes');
                    }}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
              
            </form>
  </CustomTabPanel>
  <CustomTabPanel value={nestedValue} index={2}>
  <form onSubmit={handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  
              
  
                  
                  {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Recipe Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Recipe Name"
                    />
                  </Grid> */}
               <Recipe type={3} getRecipeByDietData={getRecipeByDietData} selectedDiet={selectedDiet} snacks={recipeByDiet} setSnacks={setRecipeByDiet}/>
                   
                      {/* Add the description text field below the video upload */}
                      {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Calorie
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Calorie"
                    />
                  </Grid>
                  
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Fat
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Fat"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                     Protien
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Protien"
                    />
                  </Grid> */}
                  
                 
                </Grid>
                
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={()=>{
                      if(currentDietImage=="")
                        {
                          showError("Diet image is required")
                          return;
                        }
                      if(currentDietName=="")
                        {
                          showError("Diet name is required");
                          return;
                        }
                        if(currentDietName.length>50)
                          {
                            showError("Diet name can't exceed 50 characters")
                            return;
                          }
                      if(id)
                      handleDietUpdate();
                      showToast("Diet updated successfully")
                      navigate('/manage-recipes');
                    }}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
              
            </form>
  </CustomTabPanel>
  
</Box>}
              </CustomTabPanel>
            </Box>
          </Card>
    <WarnModal
        setOpen={setOpen}
        open={open}
        name={"diet"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getDietsList)}
      />
      
        </div>
    </>
  );
};

export default AddRecipe;
