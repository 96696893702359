import React, { useEffect, useState } from "react";
import "./App.scss";
import Routing from "./Routes";
import "./App.scss";
function App() {

  //moved the firebase notification code to main container
  return <Routing/>;
}

export default App;
