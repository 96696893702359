import { ReactNode, useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import Sidebar from "./SideBar";
import Topbar from "./TopBar";
import { SIDEBAR_WIDTH } from "../constants";
import "./Layout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { getFromStorage, STORAGE_KEYS } from "../helpers";
import { useAppDispatch } from "../hooks/store";
import { setCredentials } from "../reducers/authSlice";
import { useLazyGetUserQuery } from "../services/auth";
import Loader from "../helpers/Loader";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "../utils/firebase";

function MainContainer(){
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isLoading,setIsLoading]=useState(false)
  const [getUser] = useLazyGetUserQuery();
  const handleSidebarToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const user=useAuth();
  const getUserDetails = async () => {
    setIsLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);

    if (token) {
      try {
        const result = await getUser({}).unwrap();
        if (result?.statusCode === 200 && token) {
          dispatch(
            setCredentials({
              user: result?.data,
              token: token,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    setIsLoading(false);

    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);

    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log(fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div
                style={{ color: "#820000" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                { event?.data?.notification?.title}
              </div>
              <div
                style={{ color: "#1d1d1d", paddingTop: "10px" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                {event?.data?.notification?.body}
              </div>
            </div>);
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Loader isLoad={isLoading}/>
      <Topbar handleSidebarToggle={handleSidebarToggle} />
    <Sidebar
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        handleSidebarToggle={handleSidebarToggle}
      />
      <Box
        className="dashboard_main mn-lyout"
        component="main"
        sx={{
          marginLeft: "auto",
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
        }}
      >
        <div className="sub-layout" style={{ paddingTop: 100 }}>
          <Outlet/>
        </div>
      </Box>
    </Box>
  );
}
export default MainContainer;
