import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDz-wccQNNA-pxt-At0Ibf2joJ80nguW00",
  authDomain: "lifefit-bd6a2.firebaseapp.com",
  projectId: "lifefit-bd6a2",
  storageBucket: "lifefit-bd6a2.appspot.com",
  messagingSenderId: "293401995623",
  appId: "1:293401995623:web:246a6e43c95f1aa3f2e7c2",
  measurementId: "G-KDCDC6VT8F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
