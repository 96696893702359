import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Visibility } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
const MnnageBodyScan = () => {
  const navigate = useNavigate();

  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];

  return (
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> Manage Body-Scan</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={" "}
              // setDebouncedSearchTerm={setDebouncedSearchTerm}
              setDebouncedSearchTerm={()=>{}}
              value={" "}
              onCross={() => {}}
              onChange={()=>{}}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-bodyScan/add")}
              >
                Add Body-Scan
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                   
                  <TableCell>Title</TableCell>
                  <TableCell>Feature Info</TableCell>
                  {/* <TableCell>Description</TableCell> */}
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>
                        <figure className="user_img">
                          <img src="/static/images/bodyscan.png" alt="" />
                        </figure>
                      </TableCell>
                    <TableCell>Body Scan</TableCell>
                    <TableCell>Scan</TableCell>
                    {/* <TableCell>Full body scanning</TableCell> */}
                    
                    
                    

                    <TableCell align="center">
  <Switch {...label} defaultChecked size="small" />
</TableCell>

                    <TableCell>
                     
                     
                      <Box className="table_actions">
                      <IconButton     onClick={() => navigate("/manage-bodyScan/details")}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => navigate("/manage-bodyScan/add")}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
  );
};

export default MnnageBodyScan;
