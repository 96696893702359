import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { generateResponsiveStyle } from "../../utils/modalStyle";

type props = {
  openLogout: boolean;
  setOpenLogout: Dispatch<SetStateAction<boolean>>;
  handleLogout: () => void;
};

const LogoutModal = ({ openLogout, setOpenLogout, handleLogout }: props) => {
  const style = generateResponsiveStyle();
  return (
    <Modal
      open={openLogout}
      onClose={() => setOpenLogout(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ position: "relative", marginTop: 20 }}>
          <div className="cross_icn_logout"style={{display:"flex",justifyContent:"end"}}>
            <CloseIcon sx={{cursor:"pointer"}}onClick={() => setOpenLogout(false)} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "19px" }}
          >
            Are you sure, you want to logout?
          </Typography>
          <div className="flexDiv" style={{display:"flex",justifyContent:"center",paddingTop:"25px"}}>
            <Button
            sx={{
              // color: "#ffdc60",
              border: "1px solid #ffdc60",
              textTransform: "capitalize",
              marginInline:"15px",
            }}
              className="btn btn_primary"
              onClick={() => {
                setOpenLogout(false);
                handleLogout();
              }}
            >
              Yes
            </Button>

            <Button
              sx={{
                // color: "#ffdc60",
                border: "1px solid #ffdc60",
                textTransform: "capitalize",
              marginInline:"15px",

              }}
              className="btn btn_primary"

              onClick={() => setOpenLogout(false)}
            >
              NO
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
