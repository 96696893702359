import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Modal,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
 
 
// import {
//   Challenges,
//   Details,
  
//   PostsAndStories,
// } from "../../../features";
import MainContainer from "../../layout/MainContainer";
import { Details } from "../../features";
import DetailsExercises from "../../features/exercises/detailsExercise";
import { showError } from "../../constants/toast";
import { useLazyGetexercisesByIdQuery } from "../../services/exercise";
import HTMLReactParser from "html-react-parser";
import { Visibility } from "@mui/icons-material";
import { useLazyGetTrainingPlansByIdQuery } from "../../services/trainingplan";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TrainingPlandetails = () => {
  const[trainingData,setTrainingData]=useState<any>([]);
  const navigate = useNavigate();
  const{id}=useParams();
  const [value, setValue] = React.useState(0);
  const[open,setOpen]=useState(false);
  const [index,setIndex]=useState<any>("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const[getDataById]:any=useLazyGetTrainingPlansByIdQuery();
  const getDataByIdData=async()=>{
    try{
    const response=await getDataById({id:id}).unwrap();
    if(response.statusCode==200)
    {
    console.log(response?.data);
    setTrainingData(response.data);
    }
    }
    catch(error:any)
    {
    showError(error?.data?.message);
    }

  }
  function displayPlanType(){
    if(trainingData?.planType==1)
    {
      return "Home"
      }
    else if(trainingData?.planType==2)
    {
      return "GYM"

        }
    else
    {
      return ""

    }
  } 
  function displayPlanCategory(){
    if(trainingData?.category==1)
      {
        return "Fat Loss";
      }
      else if(trainingData?.category==2)
      {
        return "Muscle Building"
      }
      else if(trainingData?.category==3)
      {
        return "Maintain"
      }
      else{

        return ""
      }
    }
  useEffect(()=>{
    if(id)
    getDataByIdData();
  },[])
  return (
    <div>
      
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">View Training Plan Details</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-training-plan");
              }}
            >
              Back
            </Button>
          </div>
          
          
          {/* <Card className="cards"> */}
            <Box className="custom_tabs">
            <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img src={trainingData?.image?trainingData?.image:"/static/images/exer.png"} alt="" />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Training Plan Name</Typography>
                  <Typography component="p">{trainingData.trainingPlan}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Status</Typography>
                  <Typography component="p">{trainingData.isBlocked?"InActive":"Active"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Training Plan Category</Typography>
                  <Typography component="p">{displayPlanCategory()}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Training Plan Type</Typography>
                  <Typography component="p">{displayPlanType()}</Typography>
                </Box>
              </Grid>


              <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650,maxWidth:850 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Day</TableCell>
                    <TableCell>Number of Excercises</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainingData?.planDetail?.length==0?
                  <TableRow>
      <TableCell colSpan={4} align="center">No Days Added</TableCell>
      </TableRow>
      :(trainingData?.planDetail?.map((data:any,index2:any)=>{
return<TableRow>
<TableCell align="center">{data.day}</TableCell>
<TableCell>{data.exercises.length}</TableCell>
<TableCell>
<Box className="table_actions">
    <IconButton onClick={()=>{
      setIndex(index2);
      setOpen(true);
    }}>
        
        <Visibility/>
    </IconButton>
</Box>
    </TableCell>
  </TableRow>
                  }) 
                 
                  )}
                </TableBody>
              </Table>
            </TableContainer>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Status</Typography>
                  <Typography component="p"></Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Active buy</Typography>
                  <Typography component="p">10k+</Typography>
                </Box>
              </Grid> */}
              
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Rating</Typography>
                  Render rating stars
                  <Box display="flex">
                    {renderRatingStars()}
                  </Box>
                </Box>
              </Grid> */}

            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Dialog open={open} onClose={()=>setOpen(false)} className="revert_dialog">

<DialogContent>
               {/* <Modal open={open} onClose={()=>setOpen(false)} sx={{padding:"15%"}}>  */}
                <Card sx={{padding:"5%"}}>
                <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5" className="custom_label">Training Plan Name</Typography>
                  <Typography component="p" className="custom_label">{trainingData.trainingPlan}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5" className="custom_label">Training Day</Typography>
                  <Typography component="p" className="custom_label">{trainingData?.planDetail?.[index]?.day}</Typography>
                </Box>
              </Grid>
              </Grid>

                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Exercise Name</TableCell>
                        <TableCell>Number of Sets</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {trainingData?.planDetail?.[index]?.exercises?.length===0?<TableRow>
                        <TableCell align="center" colSpan={6}>
                          No Exercise Found
                        </TableCell>
                      </TableRow>:(trainingData?.planDetail?.[index]?.exercises?.map((row:any, i:number) => (
                        <TableRow key={i}>
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell>
                            <figure className="user_img">
                              <img src={row?.exercise?.image?row?.exercise?.image:"/static/images/exer.png"} alt="" />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.exercise?.exerciseName}</TableCell>
                          <TableCell>{row?.exercise?.repDetails?.length}</TableCell>     
                        </TableRow>
                      )))}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Card>
               {/* </Modal> */}
                    
               </DialogContent>
            </Dialog>
            </Box>
          {/* </Card> */}
        </div>
    
    </div>
  );
};

export default TrainingPlandetails;
