import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import { Add } from "@mui/icons-material";

const WorkoutRoutineManagement = () => {
  const navigate = useNavigate();

  function createData(
    Title: string,
    Content: string,
    Format: string,
    Category: string,
    IsPersonal: boolean,
    Subscription: boolean
  ) {
    return { Title, Content, Format, Category, IsPersonal, Subscription };
  }

  const rows = [
    createData(
      "Sample Workout Routine 1",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "E-book",
      "Muscle Building",
      true,
      true
    ),
    createData(
      "Sample Workout Routine 2",
      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "PDF",
      "Weight Loss",
      false,
      false
    ),
    // Add more sample data as needed
  ];

  return (

      <div className="main_layout">
        <div className="dashboard">
          <h3 className="mn_hdng">Manage Workout Routines</h3>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={" "}
              // setDebouncedSearchTerm={setDebouncedSearchTerm}
              setDebouncedSearchTerm={()=>{}}
              value={" "}
              onCross={() => {}}
              onChange={()=>{}}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/workout-routines-management/add")}
              >
               {<Add/>} Add Workout Routine
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Exercise name</TableCell>
                  <TableCell>Content</TableCell>
                  <TableCell>Format</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Accessibility</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row.Title}</TableCell>
                    <TableCell>{row.Content}</TableCell>
                    <TableCell>{row.Format}</TableCell>
                    <TableCell>{row.Category}</TableCell>
                    <TableCell>
                      {row.IsPersonal ? <LockIcon /> : null}
                    </TableCell>
                    <TableCell>
                      {row.Subscription ? <CheckIcon /> : <CloseIcon />}
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate("/workout-routines-management/details")
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate("/workout-routines-management/add")
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>

  );
};

export default WorkoutRoutineManagement;
