//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { SurveyResponse } from "../types/Survey";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
import { SurveyResponse } from "../types/survey";
type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetAllSurveyResponse = {
    // Survey: SurveyResponse[];
    data: any;
    count: number;
  };
  
type GetTokenParams = {
    // limit?: number;
    page?: number;
    query?: string;
  };
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getSurvey:builder.query<
CommonResponseType & {data:GetAllSurveyResponse},
    GetTokenParams>({
      
        // query:({page,limit,query})=>{
        query:({})=>{
          let url = END_POINTS.survey;
        // const queryParams = [];
        // if (page) {
          // queryParams.push(`page=${page}`);
        // }
        // if (query) {
          // queryParams.push(`search=${query}`);
        // }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        // if (queryParams.length > 0) {
        //   url += `?${queryParams.join('&')}`;
        // }
          return{
            url:url,
            method:'GET',}
        }
}),

getSurveysById:builder.query<CommonResponseType & {data:SurveyResponse},
{id:string}>({
    query:({id})=>({
        url:`${END_POINTS.survey}/${id}`,
        method:"GET",
    })
}),

changeSurveyStatus: builder.query<
CommonResponseType & { data: SurveyResponse },
{ id: string }
>({
query: ({ id }) => ({
  url: `${END_POINTS.changeUserStatus}/${id}`,
  method: "GET",
}),
}),

editSurveyById: builder.mutation<
      CommonResponseType & { data: SurveyResponse },
      { id: string|undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.survey}/${id}`,
        method: "PUT",
        body,
      }),
    }),

deleteSurveyById:builder.mutation<
CommonResponseType & {data:SurveyResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.survey}/${id}`,
        method:'DELETE'
    })
}),

addSurvey:builder.mutation<
CommonResponseType & {data:any},
CommonBody
>({
  query:(body)=>({
    url:END_POINTS.survey,
    method:'POST',
    body
  })
})

})
})
export const{
 useLazyGetSurveyQuery,
 useLazyGetSurveysByIdQuery,
 useEditSurveyByIdMutation,
 useAddSurveyMutation,
 useDeleteSurveyByIdMutation

}=userApi;
