import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import { useLazyGetCmsQuery,  useUpdateCmsMutation } from "../../services/cms";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import * as Yup from "yup";
import { Formik,useFormik } from "formik";
import useAuth from "../../hooks/useAuth";
import Loader from "../../helpers/Loader";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const user=useAuth();
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [aboutUs, setAboutUs] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const[email,setEmail]=React.useState("");
  const[phoneDisplay,setPhoneDisplay]=React.useState("");
  const[phone,setPhone]=React.useState("");
  const[isLoading,setIsLoading]=useState(false);

  const [value, setValue] = React.useState(0);
  const [phoneCode, setPhoneCode] = useState("+91");
  
  const[getCms]=useLazyGetCmsQuery();

  const[putCms]=useUpdateCmsMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
        
        
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      updateCmsData();
    }
  });
  const getCmsData=async()=>{ 
    setIsLoading(true);
    try{
    const response=await getCms({}).unwrap();
    if(response.statusCode==200)
    { 
      setPrivacyPolicy(response?.data?.privacyPolicy);
      setTermsAndConditions(response?.data?.termsAndCondition);
      setAboutUs(response?.data?.about_us);
      formik.setFieldValue("email", response.data.email);
      formik.setFieldValue("phone", response?.data?.phone);
      setPhoneCode(response?.data?.countryCode);
      setPhoneDisplay(response?.data?.countryCode+response?.data?.phone)
      console.log("CMS FETCH SUCCESS");
    }
    setIsLoading(false);
  }
  catch(error)
  {
    console.log(error);
  }}
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneDisplay(country?.dailcode+phone);
    setPhoneCode(country?.dialCode);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const updateCmsData=async()=>{
    try{
      const body={
        privacyPolicy:privacyPolicy,
        email:formik.values.email,
        phone: formik.values.phone,
        countryCode:(phoneCode.includes("+") ? "" : "+") + phoneCode,
        termsAndCondition:termsAndConditions,
        about_us:aboutUs,
      }
      console.log('body',body);
      const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
      const response=await putCms(encryptedData).unwrap();
      if(response.statusCode==200)
      {
        showToast("CMS updated sucessfully");
        console.log("CMS UPDATE SUCCESS");
      }
    }
    catch(error:any){
      showError(error?.data?.message);
      console.log(error);
    }
  }

  React.useEffect(()=>{
    getCmsData();
  },[])

  return (
      <div className="main_loyout">
             <Loader isLoad={isLoading}/>
        <div className="dashboard">
          <h1 className="mn_hdng">Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              {/* <Tab label="About Us" {...a11yProps(1)} /> */}

              <Tab label="Privacy Policy" {...a11yProps(1)} />
              <Tab label="Terms and Conditions" {...a11yProps(2)} />
              <Tab label="About Us" {...a11yProps(3)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.email && formik.errors.email}
                      placeholder="Email"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      value={phoneDisplay}
                      onChange={handleChangePhone}
                      inputStyle={{ width: "100%" }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  {(user?.role==1)||(user?.permissions?.[9]?.isEdit)?<Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>:null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {/* <form>
                <EditText content={aboutUs} setContent={setAboutUs} />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form> */}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                <div className="form_btn">
                  {(user?.role==1)||(user?.permissions?.[9]?.isEdit)?<Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"

                  >
                    Save
                  </Button>:null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                <div className="form_btn">
                  {(user?.role==1)||(user?.permissions?.[9]?.isEdit)?<Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"

                  >
                    Save
                  </Button>:null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={aboutUs}
                  setContent={setAboutUs}
                />
                <div className="form_btn">
                 {(user?.role==1)||(user?.permissions?.[9]?.isEdit)? <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"

                  >
                    Save
                  </Button>:null}
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
  );
};

export default ManageCms;
