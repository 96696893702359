//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllFeatureResponse = {
    feature: any;
    count: number;
  };
type FeatureResponse={
    _id:string,
    name:string,
    image:string,
    isBlocked:string
}
type GetTokenParams = {
    // limit?: number;
    page?: number;
    query?: string;
  };

type EncryptedBody = {
  hash: string;
  sek: string;
} | null
export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getFeature:builder.query<
CommonResponseType & {data:GetAllFeatureResponse},
    {}>({
      
        // query:({page,limit,query})=>{
        query:()=>{
          let url = END_POINTS.feature;
        // const queryParams = [];
        // if (page) {
        //   queryParams.push(`page=${page}`);
        // }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        // if (queryParams.length > 0) {
        //   url += `?${queryParams.join('&')}`;
        // }
          return{
            url:url,
            method:'GET',}
        }
}),
getFeatureById:builder.query<CommonResponseType & {data:FeatureResponse},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.feature}/${id}`,
        method:"GET",
    })
}),
editFeatureById: builder.mutation<
      CommonResponseType & { data: FeatureResponse },
      { id: string|undefined; body: EncryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.feature}/${id}`,
        method: "PUT",
        body,
      }),
    }),

addFeature: builder.mutation<
CommonResponseType & { data: FeatureResponse },
CommonBody
>({
query: (body) => ({
  url: END_POINTS.feature,
  method: "POST",
  body,
})
}),

deleteFeatureById:builder.mutation<
CommonResponseType & {data:FeatureResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.feature}/${id}`,
        method:'DELETE'
    })
}),

})
})

export const{
 useLazyGetFeatureQuery,
 useLazyGetFeatureByIdQuery,
 useAddFeatureMutation,
 useDeleteFeatureByIdMutation,
 useEditFeatureByIdMutation,
}=userApi;
