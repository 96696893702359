// PageNotFound.js

import React from 'react';

function PageNotFound() {
  return (
    <div>
      <h2>404 - Page Not Found</h2>
      <p>This page doesn't exist.</p>
    </div>
  );
}

export default PageNotFound;
