import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { Box, Button, FormControl, Grid } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLazyGetEarningGraphQuery, useLazyGetEarningsCSVQuery, useLazyGetSubscriptionGraphQuery, useLazyGetSubscriptionsCSVQuery, useLazyGetUserGraphQuery, useLazyGetUsersCSVQuery } from "../../services/analytics";
import { showError } from "../../constants/toast";
import { useLazyGetUserCSVQuery } from "../../services/user";

const Analytics = () => {

  const [userType,setUserType]=React.useState("daily");
  const[userData,setUserData]=React.useState<any>({
    labels: [],
    datasets: [
      {
        label: "Users",
        data: [],
        borderColor: "black",
        backgroundColor: "#ECFC73",
        color: "#FFFFF", 
      },
    ],
  });
  const [earningType,setEarningType]=React.useState("daily");
  const[earningData,setEarningData]=React.useState<any>({
    labels: [],
    datasets: [
      {
        label: "Earning",
        data: [],
        borderColor: "black",
        backgroundColor: "#ECFC73",
        color: "#FFFFF", 
      },
    ],
  });
  const [subscriptionType,setSubscriptionType]=React.useState("daily");
  const[subscriptionData,setSubscriptionData]=React.useState<any>({
    labels: [],
    datasets: [
      {
        label: "Subscription",
        data: [],
        borderColor: "black",
        backgroundColor: "#ECFC73",
        color: "#FFFFF", 
      },
    ],
  });
  const[getUsers]=useLazyGetUserGraphQuery();
  const[getEarning]=useLazyGetEarningGraphQuery();
  const[getSubscription]=useLazyGetSubscriptionGraphQuery();
  const [getUserCsvMethod] = useLazyGetUsersCSVQuery();
  const [getEarningCsvMethod] = useLazyGetEarningsCSVQuery();
  const [getSubscriptionCsvMethod] = useLazyGetSubscriptionsCSVQuery();
 const handleExportUserCsv = async () => {
    try {
      const res = await getUserCsvMethod(null).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };
  const handleExportEarningCsv = async () => {
    try {
      const res = await getEarningCsvMethod(null).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };
  const handleExportSubscriptionCsv = async () => {
    try {
      const res = await getSubscriptionCsvMethod(null).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };
  

  const getUserData = async () => {
    try {
      const response = await getUsers(userType).unwrap();
      if (response?.statusCode === 200) {
        console.log("users",response);
        const labels=[];
        const data=[];
        for(let key in response?.data?.totalUsers)
        {
          labels.push(key);
          data.push(response?.data?.totalUsers[key]);
        }
        setUserData(
          {
            labels: labels,
            datasets: [
              {
                label: "Users",
                data: data,
                borderColor: "black",
                backgroundColor: "#ECFC73",
                color: "#FFFFF", 
              },
            ],
          }
        )
      } else {
        setUserData({
          labels: [],
          datasets: [
            {
              label: "Users",
              data: [],
              borderColor: "black",
              backgroundColor: "#ECFC73",
              color: "#FFFFF", 
            },
          ],
        });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  React.useEffect(()=>{
    getUserData();
  },[userType])

  const getEarningData = async () => {
    try {
      const response = await getEarning(earningType).unwrap();
      if (response?.statusCode === 200) {
        const labels=[];
        const data=[];
        for(let key in response?.data?.revenuGenerated)
        {
          labels.push(key);
          data.push(response?.data?.revenuGenerated[key]);
        }
        setEarningData(
          {
            labels: labels,
            datasets: [
              {
                label: "Earning",
                data: data,
                borderColor: "black",
                backgroundColor: "#ECFC73",
                color: "#FFFFF", 
              },
            ],
          }
        )
      } else {
        setEarningData({
          labels: [],
          datasets: [
            {
              label: "Earning",
              data: [],
              borderColor: "black",
              backgroundColor: "#ECFC73",
              color: "#FFFFF", 
            },
          ],
        });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  React.useEffect(()=>{
    getEarningData();
  },[earningType])
  const getSubscriptionData = async () => {
    try {
      const response = await getSubscription(subscriptionType).unwrap();
      if (response?.statusCode === 200) {
        // console.log("Subscription",response);
        const labels=[];
        const data=[];
        for(let key in response?.data?.totalSubscription)
        {
          labels.push(key);
          data.push(response?.data?.totalSubscription[key]);
        }
        setSubscriptionData(
          {
            labels: labels,
            datasets: [
              {
                label: "Subscription",
                data: data,
                borderColor: "black",
                backgroundColor: "#ECFC73",
                color: "#FFFFF", 
              },
            ],
          }
        )
      } else {
        setUserData({
          labels: [],
          datasets: [
            {
              label: "Subscription",
              data: [],
              borderColor: "black",
              backgroundColor: "#ECFC73",
              color: "#FFFFF", 
            },
          ],
        });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  React.useEffect(()=>{
    getSubscriptionData();
  },[subscriptionType])

 

 


  return (

      <div className="main_loyout">
        
        <div className="dashboard">
          <div className="flex">
            <h1 className="mn_hdng">Reports and Analytics</h1>
           
          </div>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="cards dashGraph_item">
            <h2 style={{fontSize:"15px"}}  className="mn_hdng">

                <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
                Total Users
                <Box display={"flex"}>
                    <Box className="cards_header_right" paddingInline={"10px"}>
                  <Button className="btn btn_primary" onClick={handleExportUserCsv}>
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
                   <Box>
                      <FormControl>
                     
                        <NativeSelect
                          defaultValue={userType}
                          onChange={(e)=>setUserType(e.target.value)}
                          inputProps={{
                            name: "report",
                            id: "uncontrolled-native",
                          }}
                        >
                          <option value={0} disabled>Select</option>
                          <option value={'daily'}>Daily</option>
                          <option value={'weekly'}>Weekly</option>
                          <option value={'monthly'}>Monthly</option>
                          <option value={'yearly'}>Yearly</option>
                        </NativeSelect>
                      </FormControl>
                   </Box>
                </Box>
                </Box>
              </h2>
              <LineChart data={userData} />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              {/* <h2 className="mn_hdng"> */}
              <h2 style={{fontSize:"15px"}}  className="mn_hdng">
              <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>

              Total Earnings
              <Box display={"flex"}>

              <Box className="cards_header_right" paddingInline={"10px"}>
              <Button className="btn btn_primary"
              onClick={handleExportEarningCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
                <Box>
                  <FormControl>
                    <NativeSelect
                    defaultValue={earningType}
                    onChange={(e)=>setEarningType(e.target.value)}
  
                    inputProps={{
                        name: "report",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={0} disabled>Select</option>
                      <option value={'daily'}>Daily</option>
                      <option value={'weekly'}>Weekly</option>
                      <option value={'monthly'}>Monthly</option>
                      <option value={'yearly'}>Yearly</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
                </Box>
                </Box>
              </h2>
              <LineChart data={earningData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 style={{fontSize:"15px"}} className="mn_hdng">
              <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>

                Total Subscriptions
              <Box display={"flex"}>

                <Box className="cards_header_right" paddingInline={"10px"}>
              <Button className="btn btn_primary"
              onClick={handleExportSubscriptionCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
               <Box>
                  <FormControl>
                    <NativeSelect
                      defaultValue={subscriptionType}
                      onChange={(e)=>setSubscriptionType(e.target.value)}
                      inputProps={{
                        name: "report",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={0} disabled>Select</option>
                      <option value={'daily'}>Daily</option>
                      <option value={'weekly'}>Weekly</option>
                      <option value={'monthly'}>Monthly</option>
                      <option value={'yearly'}>Yearly</option>
                    </NativeSelect>
                  </FormControl>
                  </Box>
                  </Box>
               </Box>
               
              </h2>
              <LineChart data={subscriptionData} />
            </div>
          </Grid>
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Earnings
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Transactions
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphTransactionsData} />
            </div>
          </Grid> */}
        </Grid>
      </div>
  );
};

export default Analytics;
