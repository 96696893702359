import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { showError, showToast } from "../../constants/toast";
import { useLazyGetUsersQuery, useEditUserByIdMutation, useDeleteUserByIdMutation, useLazyGetUserCSVQuery } from "../../services/user";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import users from "../users";
import { useDeleteexerciseByIdMutation, useEditexerciseByIdMutation, useLazyGetexerciseQuery } from "../../services/exercise";
import Loader from "../../helpers/Loader";
import useAuth from "../../hooks/useAuth";
import { Add } from "@mui/icons-material";
const ManageExercises = () => {
  const navigate = useNavigate();
  const user:any=useAuth();
  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [Exercises, setExercises] = useState<any>([]);
  const [getExercise] = useLazyGetexerciseQuery();
  const [updateUserStatus]:any = useEditexerciseByIdMutation();
  const [deleteById] = useDeleteexerciseByIdMutation();
  let totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getExerciseList = async () => {
    setIsLoading(true);
    try {
      const response = await getExercise({
        page: page,
        // limit: 10,
        wpagination:false,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log(response?.data);
        setExercises(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setExercises([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

 const handleStatusChange=async(index:any)=>{
    try {
      const body = {
        isBlocked:!Exercises[index]?.isBlocked
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateUserStatus({ id: `${Exercises[index]._id}` , body:encryptedData}).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setExercises((prevData:any)=>{
        let temp=[...prevData];
        temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
 useEffect(() => {
//   console.log("TESTING")
    getExerciseList();
  }, [debouncedSearchTerm, page]);
  console.log(Exercises);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);

  // Declare rows variable here
  const rows = [
    { Name: "Yoga", Exetcise_Category: "Aerobic", Exetcise_Sub_Category: "Flexibility and Stretching", Description: "2 times daily" },
    { Name: "Stretching", Exetcise_Category: "Cardiovascular", Exetcise_Sub_Category: "Balance and Stability", Description: "3 times daily" }
  ];

  // // Sample data for demonstration
  // function createData(Name: any, Exetcise_Category: any, Exetcise_Sub_Category: any, Description: any) {
  //   return { Name, Exetcise_Category, Exetcise_Sub_Category, Description };
  // }

  const [exerciseVisibility, setExerciseVisibility] = useState(Array(rows.length).fill(true));

  const toggleVisibility = (index: number) => {
    setExerciseVisibility((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
   
      <div className="main_loyout">
        <Loader isLoad={isLoading}/>
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Exercises</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
                
        

              </Box>
              <Box className="cards_header_right">
                
               

                {(user?.role==1)||(user?.permissions?.[10]?.isEdit)?<Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-exercises/add")}
              >
                {<Add/>}Add Exercise
              </Button>:null}



              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Exercise Name</TableCell>
                    <TableCell>Number of Sets</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Exercises.length===0?<TableRow>
                    <TableCell align="center" colSpan={6}>
                      No Exercise Found
                    </TableCell>
                  </TableRow>:(Exercises.map((row:any, i:number) => (
                    <TableRow key={i}>
                      <TableCell align="center">{(page-1)*10 +i + 1}</TableCell>
                      <TableCell>
                        <figure className="user_img">
                          <img src={row?.image?row?.image:"/static/images/exer.png"} alt="" />
                        </figure>
                      </TableCell>
                      <TableCell>{row?.exerciseName}</TableCell>
                      <TableCell>{row?.repDetails?.length}</TableCell>
                      <TableCell>
                        <Switch
                          checked={!row?.isBlocked}
                          disabled={!((user?.role==1)||(user?.role==2 && user?.permissions?.[10].isEdit))}
                          onChange={()=>handleStatusChange(i)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton onClick={() => navigate("/manage-exercises/details/"+row?._id)}>
                    
                              <VisibilityIcon />
                      
                          </IconButton>
                          {(user?.role==1)||(user?.permissions?.[10]?.isEdit)?<IconButton
                          onClick={() => navigate("/manage-exercises/edit/"+row?._id)}
                        >
                          <ModeEditIcon />
                        </IconButton>:null}
                          {(user?.role==1)||(user?.permissions?.[10]?.isDelete)? <IconButton
                        onClick={() => {setSelectedId(row._id)
                          setOpen(true);}
                        }
                        >
                          <DeleteIcon />
                        </IconButton>:null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        <Pagination
          module={Exercises}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={"Exercise"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getExerciseList)}
      />
      </div>

 
  );
};

export default ManageExercises;
