import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    TextField,
    Typography,
    Input,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    Tabs,
    Tab,
    SelectChangeEvent,
    FormControl,
    Select,
    MenuItem,
    Switch,
    IconButton,
    InputLabel,
    FormControlLabel,
    Modal,
    Dialog,
    DialogContent,
  } from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  
  import MainContainer from "../../layout/MainContainer";
  import { useNavigate, useParams } from "react-router-dom";
  import { ChangeEvent, FormEvent, useEffect, useState } from "react";
  import CameraAltIcon from "@mui/icons-material/CameraAlt";
  import CancelIcon from "@mui/icons-material/Cancel";
  import EditText from "../../components/EditText";
  import AddIcon from "@mui/icons-material/Add"; 
  import { Cancel, CameraAlt, Edit } from "@mui/icons-material";
  import { handleDelete } from "../../utils/commonFunctions";
  import { errorToast } from "../../helpers";
  import { UploadMedia, UploadVideo } from "../../utils/uploadMedia";
  import { showError, showToast } from "../../constants/toast";
  import { CommonBody } from "../../types/General";
  import { generateEncryptedKeyBody } from "../../utils/crypto";
  import { useAddworkoutCategoryMutation, useDeleteworkoutCategoryByIdMutation, useEditworkoutCategoryByIdMutation, useLazyGetworkoutCategoryQuery } from "../../services/workoutCategory";
  import { Pagination } from "../../components";
  import { workCategoryResponse } from "../../types/workoutCategory";
  import Loader from "../../helpers/Loader";
  import { useAddworkoutVideoMutation, useEditworkoutVideoByIdMutation, useLazyGetworkoutVideoByIdQuery } from "../../services/workoutVideos";
  import WarnModal from "../../components/modals/WarnModal";
import { useLazyGetexerciseQuery } from "../../services/exercise";
import { useAddTrainingPlanMutation, useEditTrainingPlanByIdMutation, useLazyGetTrainingPlansByIdQuery } from "../../services/trainingplan";
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  interface Role {
    name: string;
    enabled: boolean;
  }
  const AddTrainingPlan = () => {
   const{id}= useParams();
   const[oldData,setOldData]=useState<any>({});

   const[paid,setPaid]=useState<number>(0);
   const[open,setOpen]=useState(false);
   const[selectedId,setSelectedId]=useState<any>("");
    const[error,setError]=useState(false);
    const navigate = useNavigate();
    const [role, setRole] = useState("");
    const [rolesData, setRolesData] = useState<Role[]>([]);
    const [newRole, setNewRole] = useState("");
    const[trainingPlanImage,setTrainingPlanImage]=useState("");
    const[videoLink,setVideoLink]=useState("");
    const [loading, setLoading] = useState(false);
    const [thumbnail, setThumbnail] = useState<string>("");

    const[displayEditText,setDisplayEditText]=useState<boolean>(true);
    const[currentVideoDetails,setCurrentVideoDetails]=useState<any>(null);
    const [value, setValue] = useState<number>(0);
    const [category, setCategory] = useState<any>("");
    const [subCategory, setSubCategory] = useState<any>("");
    const [day, setDay] = useState<any>("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(0);
    const [image, setImage] = useState<string>("");
    const [video, setVideo] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const[imageId,setImageId]=useState(null);

    
    
    const[newTrainingPlan,setNewTrainingPlan]=useState("");
    const[newTrainingPlanCategory,setNewTrainingPlanType]=useState<any>("");

    const [isAdding,setIsAdding]=useState(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState(1);
    const[duration,setDuration]=useState("");
    const[exerciseName,setExerciseName]=useState("");
    const[getCategory]:any=useLazyGetworkoutCategoryQuery();
    const[addCategory]=useAddworkoutCategoryMutation();
    const[deleteById]=useDeleteworkoutCategoryByIdMutation();
    const[updateCategoryStatus]=useEditworkoutCategoryByIdMutation();
    const[addTrainingPlan]:any=useAddTrainingPlanMutation();
    const[updateTrainingPlan]=useEditTrainingPlanByIdMutation();
    const[getTrainingPlanById]=useLazyGetTrainingPlansByIdQuery();
    const [dayDetails,setDayDetails]=useState([]);
    const[addNewDay,setAddNewDay]=useState(false);
    const[exercise,setExcercise]=useState("");
    const[isLoading,setIsLoading]=useState(false);
    let totalPages = Math.ceil(totalCount/10);
    const [editIndex,setEditIndex]=useState<any>("");
    const [editModal,setEditModal]=useState(false);
    const [dayData,setDayData]=useState<any>([
      {
      day:"Day 1",
      },
      {
        day:"Day 2",
      },
      {
        day:"Day 3",
      },
      {
        day:"Day 4",
      },
      {
        day:"Day 5",
      },
      {
        day:"Day 6",
      },
    ]);
    const [exerciseData,setExcerciseData]=useState<any>([]);
    const[excerciseContent,setExcerciseContent]=useState<any>([]);
    const [trainingDays,setTrainingDays]=useState<any>([]);
    const[exerciseArray,setExcerciseArray]=useState<any>([]);
    const[dayCode,setDayCode]=useState("");
    const [getExercise] = useLazyGetexerciseQuery();
    const getExerciseList = async () => {
      setIsLoading(true);
      try {
        const response = await getExercise({
          page: page,
          wpagination:true,
          // limit: 10,
          query:"",
        }).unwrap();
        if (response?.statusCode === 200) {
          console.log(response?.data);
          setExcerciseContent(response?.data || []);
          setExcerciseData(response?.data?.map((data:any)=>{return {...data,isChecked:false}}))
          setTotalCount(response?.data?.count);
        } else {
          setExcerciseContent([]);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
      setIsLoading(false);
    };
    console.log(exerciseData);
    console.log(excerciseContent);
    useEffect(()=>{
getExerciseList();
    },[])
    const onPageChange = (newPage: number) => {
      setPage(newPage);
    };
    
    const addTrainingPlanData=async()=>{
      console.log("OOOOLLLLLLLDDDDDDDDDDDD",oldData);
      if(newTrainingPlan=="")
        {
          showError("Training plan is required")
          return;
        }
        if(category=="")
          {
            showError("Training plan category is required")
            return;
          }
          if(subCategory=="")
            {
              showError("Training plan type is required")
              return;
            }
            let daysToDelete=[];
            for(let key in oldData)
            { 
              let flag=0;
              for(let key2 in trainingDays)
              {   console.log(oldData[key].day==trainingDays[key2].day);
                if(oldData[key].day===trainingDays[key2].day)
                  {
                    flag=1;
                    break;
                  }
              }  
              if(flag==0)
                { 
                  // console.log("OLD DATA",oldData[key].day);
                  daysToDelete.push(oldData[key]._id);
                }
            }
            console.log(daysToDelete);
            // const sortedTrainingDays=trainingDays.sort(compare);
            // for(let key of sortedTrainingDays)
            //   {
            //     console.log("SORTED",key.day);
            //   }
            //   console.log("SORT",sortedTrainingDays?.map((data:any)=>{
            //     return {day:data?.day,exercises:data?.exercises?.map((data:any)=>data.exerciseId)}
            //     }));
            // sortedTrainingDays.sort(compare);
            const body={
              trainingPlan:newTrainingPlan,
              planType:subCategory,
              image:trainingPlanImage,
              // planDetail:trainingDays,
              planDetail:trainingDays?.map((data:any)=>{
              return {day:data?.day,exercises:data?.exercises?.map((data:any)=>data.exerciseId)}
              }),
              deleteDay:daysToDelete,
              category:category
            }
            console.log("ADD BODY",body);
      try{
        const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
        try{ 
         const response=(id ? await updateTrainingPlan({id:id,body:encryptedBody}).unwrap()  : await addTrainingPlan(encryptedBody).unwrap() );
         if(response.statusCode==200)
         {
           showToast(id?"Training plan updated succesfully":"Training plan added successfully");
     ////        console.log("body",body);
     navigate('/manage-training-plan');
         }}
         catch(error:any)
         {
             showError(error.data.message);
         }
      }
      catch(error:any)
      {
        showError(error?.data?.message);
      }
    }
    const handleCategoryChange = (event: SelectChangeEvent) => {
      setCategory(parseInt(event.target.value));
    };
    const handleSubCategoryChange = (event: SelectChangeEvent) => {
      setSubCategory(parseInt(event.target.value));
    };
    useEffect(()=>{
      // setInitialExercise();
      if(editModal)
      {  setExcerciseArray((prev:any)=>{
       return prev.map((data:any)=>{
        console.log("0000000000000000000000000000",trainingDays?.[editIndex]?.day,trainingDays?.[editIndex]?.exercises?.some((item:any)=>item._id==data._id));
       return {...data,isChecked:trainingDays?.[editIndex]?.exercises?.some((item:any)=>item.exerciseId==data._id)}
        })
      })}
    },[editIndex,editModal])
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files[0].type.includes("image")) {
        setFileName(files[0].name);
        setImage(URL.createObjectURL(files[0]));
      } else {
        setAlertType(0);
        setShowAlert(true);
        setAlertMessage("This field only accepts images.");
      }
    };
    const compare=(a:any,b:any)=>{
      console.log("dddddddddddddddddd",a?.day,b?.day);
      if(!(a?.day.includes("Day"))){
        return;
      }
      if(!(b?.day.includes("Day"))){
        return;
      }
      if(a?.day<b?.day)
        {
          return -1;
        }
        if(a?.day>b?.day)
          {
            return 1;
          }
    }
    try{ 
  useEffect(()=>{
    if(isAdding){
    setTrainingDays((prevData:any)=>{
      console.log("fffffffffffffff",prevData);
      return [...prevData?.sort(compare)];
    });
    setIsAdding(false);
    }
  
  },[trainingDays])
  
  }
    catch(e)
    {
      console.log(error)
  }
    // const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    //   const files = event.target.files;
    //   if (files && files[0].type.includes("video")) {
    //     setFileName(files[0].name);
    //     setVideo(URL.createObjectURL(files[0]));
    //   } else {
    //     setAlertType(0);
    //     setShowAlert(true);
    //     setAlertMessage("This field only accepts videos.");
    //   }
    // };
  
    // const handleMediaCancel = () => {
    //   setImage("");
    //   setVideo("");
    //   setFileName("");
    // };
  
    // const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    //   setDescription(event.target.value);
    // };
  
    // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //   event.preventDefault();
    //   // Handle form submission
    // };
    const handleRoleChange = (event: SelectChangeEvent) => {
      setRole(event.target.value as string);
    };
  
    const handleRoleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewRole(event.target.value);
    };
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  
    function createData(Module: string) {
      return { Module };
    }
    const label = { inputProps: { "aria-label": "Switch demo" } };
    const rows = [
      createData("Manage Users"),
      createData("Manage exercises"),
      createData("Manage Recipes"),
      createData("Workout Routine Mnagement"),
      createData("Revenue Subscription"),
      createData("Workout Videos Management"),
   
      createData("Manage Survey Questionnaries"),
   
      createData(" Notification Management"),
      createData(" Analytics"),
      createData("Manage CMS"),
    ];
  
    const handleAddRole = (event: React.FormEvent) => {
      event.preventDefault();
      if (newRole.trim() !== "") {
        setRolesData([...rolesData, { name: newRole, enabled: true }]);
        setNewRole("");
      }
    };
    const handleToggle = (index: number) => {
      setRolesData((prevRoles) =>
        prevRoles.map((role, i) =>
          i === index ? { ...role, enabled: !role.enabled } : role
        )
      );
    };
  
    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
      throw new Error("Function not implemented.");
    }
  
    function setInitialExercise(){
      setExcerciseData(excerciseContent?.map((data:any)=>{return {...data,isChecked:false}}))
    }
    const handleImageUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setLoading(true);
      const files = event.target.files;
      const file = files?.length ? files[0] : null;
  
      try {
        if (!file) {
  
          
          setTrainingPlanImage("");
          setImageId(null);
          return;
        }
        const allowedExtensions = ["png","jpg","jpeg"];
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        console.log("sssssssssssssssssssss",fileExtension);
        if (!allowedExtensions.includes(fileExtension||"")) {
          setLoading(false);
          showError("Invalid file format: only png, jpg images are allowed");
          return;
        }
        const res = await UploadMedia(file);
  //      console.log(res,'lklk');
        
        if (res?.statusCode === 200) {
    
          setTrainingPlanImage(res?.data);
        } else {
          errorToast(res?.message);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        errorToast("Error uploading image. Please try again.");
      }
      setLoading(false);
    };
  
  const getTrainingPlanByIdData=async()=>{
  
  try{
  const response=await getTrainingPlanById({id:id}).unwrap();
  if(response.statusCode==200)
  {
    setNewTrainingPlan(response?.data?.trainingPlan);
    setTrainingPlanImage(response?.data?.image);
    setCategory(response?.data?.category);
    setSubCategory(response?.data?.planType);
    setTrainingDays(response?.data?.planDetail);
   setOldData(response?.data?.planDetail);
//  console.log("+++++++++++++++",response?.data);
  }
  }
  catch(error:any)
  {
  showError(error?.data?.message);
  }
    } 
    useEffect(()=>{
      if(id)
      getTrainingPlanByIdData();
    },[])
  //   const handleStatusChange=async(index:number)=>{
  //     try {
  //       const body = {
  
  
  //         isBlocked:!newTrainingPlanCategory[index]?.isBlocked
  //       };
  //       console.log(body);
  //       const encryptedData=generateEncryptedKeyBody(body);
  //       const response = await updateCategoryStatus({ id: `${newTrainingPlanCategory[index]._id}` , body:encryptedData}).unwrap();
  //       showToast(response?.message || "User Updated Successfully");
  //       setNewTrainingPlanType((prevData:any)=>{
  //         let temp=[...prevData];
  //         temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
  //         return temp;
  //       });
  //     } catch (error: any) {
  //       showError(error?.data?.message || "");
  // //      console.log(error);
  //     }
  //   }
  console.log(trainingDays);
  
  
    
    
      console.log(exerciseData);
      // console.log(thumbnail);
      
    
  
  //  const handleAddUpdateVideo = async () => {
  //     const body={
  //       exerciseName:exerciseName,
  //       image:thumbnail,
  //       video:videoLink,
  //       duration:duration,
  //       description:description,
  //       workoutCategory:category,
  //       paid:paid?true:false
  //     }
  //     console.log(body);
  //     const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
  //    try{ 
  //     const response=(id ? await updateVideo({id:id,body:encryptedBody}).unwrap()  : await addVideo(encryptedBody).unwrap() );
  //     if(response.statusCode==200)
  //     {
  //       showToast(id?"Video details updated succesfully":"Video added successfully");
  // ////        console.log("body",body);
  //     }}
  //     catch(error:any)
  //     {
  //         showError(error.data.message);
  //     }
  
  //     };
 
    return (<>
                  <Loader isLoad={loading}/> 
  
          <div className="main_loyout">
            <div className="dashboard">
              <h1 className="mn_hdng">{"Add Training Plan"}</h1>
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-training-plan");
                }}
              >
                Back
              </Button>
            </div>
            <Card className="cards">
              <Box className="custom_tabs">
                {/* <Box className="flx_sc">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="basic tabs example"
                    className="custom_tabs_links"
                  >
                    <Tab label="Add Workout Category" {...a11yProps(0)} />
                    <Tab label={id?"Edit Workout Video":"Add Workout Videos"} {...a11yProps(1)} />
                  </Tabs>
                </Box> */}
                <CustomTabPanel value={value} index={0}>
                  <form >
                    <CardContent sx={{ p: 1 }}>
                      <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {trainingPlanImage ? (
                      <div className="upload_image_preview3">
                        <CardMedia component="img" image={trainingPlanImage} sx={ {border:"2px dotted black"}} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setTrainingPlanImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg,image/jpg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn3">
                            <img
                            style={{border:"1px dotted black"}}
                              src={
                                trainingPlanImage
                                  ? trainingPlanImage
                                  :  "/static/images/placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !trainingPlanImage ? (
                      <h6 className="err_msg">Image is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                          <Typography className="custom_label">
                            Add Training Plan
                          </Typography>
                          <TextField
                            hiddenLabel
                            type={"text"}
                            name="category"
                            variant="outlined"
                            fullWidth
                            placeholder="Add Training Plan"
                            value={newTrainingPlan}
                            onChange={(e)=>{
                              if(e.target.value.length<=100)
                              setNewTrainingPlan(e.target.value)
                              if(e.target.value.length>100)
                              showError("Plan name cant exceed 100 characters")
                            }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                        Training Plan Category
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleCategoryChange}
                          displayEmpty
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value={1} >
                            Fat Loss
                          </MenuItem>
                          <MenuItem value={2}>
                            Muscle Building
                          </MenuItem>
                          <MenuItem value={3}>
                            Maintain
                          </MenuItem>
                        
                         
                           
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                        Training Plan Type
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={subCategory}
                          onChange={handleSubCategoryChange}
                          displayEmpty
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value={1} >
                            Home
                          </MenuItem>
                          <MenuItem value={2}>
                            GYM
                          </MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                      </Grid>
                         {
                           trainingDays?.map((data:any,index:number)=>{
                             return  <Grid container spacing={2} marginBlock={"1px"}>
                               <Grid item lg={4} md={4} sm={6} xs={12} display={"flex"}>
                            <TextField
                              hiddenLabel
                              type={"text"}
                              name="category"
                              variant="outlined"
                              fullWidth
                              disabled
                              placeholder="Add Training Plan"
                              value={data.day}
                            />
                            <Box className="table_actions" display={"flex"} alignItems={"center"}>
                            <IconButton onClick={()=>{
                              setExcerciseArray(excerciseContent?.map((data:any)=>{return {...data,isChecked:false}}))
                              setEditIndex(index);
                              setEditModal(true);
                            }}>
                              <Edit/>
                            </IconButton>
                            <IconButton onClick={()=>{
                            setSelectedId(data.day);
                            setOpen(true);
                            }}>
                              <DeleteIcon/>
                            </IconButton>
                          </Box>
                          </Grid>
                      </Grid>
                          })
                         }
                      <Grid container spacing={2} marginTop={"15px"}>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                        Day
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={day}
                          onChange={(e)=>setDay(e.target.value)}
                          displayEmpty
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                         {
                          dayData.map((data:any)=>{
                            const isDisabled=trainingDays?.some((data2:any)=>data2.day===data.day)
                            return <MenuItem value={data.day} disabled={isDisabled}>{data.day}</MenuItem>
                          })
                         }
                         
                           
                        </Select>
                      </FormControl>
                    </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12} display={"flex"} alignItems={"end"}>
                      <Button className="btn btn_primary" onClick={()=>{
                        if(day=="")
                        {
                          showError("Please select a day");
                          return;
                        }
                        setAddNewDay(true)}}>Add Day</Button>
                      </Grid>

                      </Grid>
                      {addNewDay &&

<>          
  <Grid container spacing={2}  marginTop={"15px"}>
                        <Grid item lg={4} md={4} sm={6} xs={12} marginTop={"5px"}>
                        <Typography >
                        Add Exercise to Selected Day
                          </Typography>
                        </Grid>
  
                        </Grid>
                        <Grid container spacing={2} marginBlock={"20px"}>
                        {
                              exerciseData?.map((data:any)=>{
                                return (
                               <Grid item lg={4} md={4} sm={6} xs={12}>
                                  <FormControlLabel sx={{color:"black"}} style={{color:"black"}} label={data.exerciseName} control={<Checkbox checked={data.isChecked} 
                                  onChange={(e)=>setExcerciseData((prevData:any)=>{
                                   return prevData?.map((data2:any)=>{
                                      if(data2._id==data._id)
                                        {
                                          return {...data2,isChecked:!data2.isChecked}
                                        }
                                        return data2;
                                    })
                                  })} />}/>
                              </Grid>
                              )
                              })
                        } 
                        {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                        <FormControl fullWidth>
                          <Select
                            className="select_div "
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={exercise}
                            onChange={(e)=>setExcercise(e.target.value)}
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            {
                              exerciseData.map((data)=>{
                                const isDisabled = exerciseArray.some((temp:any) => temp._id === data._id);
                                return <MenuItem value={data._id} disabled={isDisabled}>
                                {data.name}
                              </MenuItem>
                              
                              })
                            } 
                          </Select>
                        </FormControl>
                      </Grid> */}
                        {/* <Grid item lg={4} md={4} sm={6} xs={12} display={"flex"} alignItems={"end"}>
                        <Button className="btn btn_primary" onClick={()=>{
                          setExcerciseArray((prevData:any)=>{
                            const tempName=exerciseData.filter(temp=>exercise===temp._id);
                            setExcercise("");
                            if(tempName.length!==0)
                            return [...prevData,{_id:exercise,name:tempName?.[0]?.name}]
                          return prevData;
                          })
                        }}>Add Excercise</Button>
                        </Grid> */}
  
                        </Grid>
                        
                 {/* {       
                  exerciseArray.map((data:any)=>{
                    return  <Grid container spacing={2} marginBlock={"1px"}>
                        <Grid item lg={4} md={4} sm={6} xs={12} display={"flex"}>
                            <TextField
                              hiddenLabel
                              type={"text"}
                              name="category"
                              variant="outlined"
                              fullWidth
                              disabled
                              placeholder="Add Training Plan"
                              value={data?.name}
                            />
                          <IconButton onClick={()=>{
                            setExcerciseArray((prevData:any)=>prevData.filter((item:any)=>item._id !== data._id))
                          }}>
                            <DeleteIcon/>
                          </IconButton>
                          </Grid>
                        </Grid>
                  })
                 
                        } */}
                         <Button
                         sx={{marginTop:"10px"}}
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                          onClick={()=>{
                            setTrainingDays((prevData:any)=>{
                          return [...prevData,{day:day,exercises:exerciseData.filter((data:any)=>data.isChecked).map((data:any)=>{
                            return {exerciseId:data._id}
                          })}]
                            })
                            showToast("Day Added Successfully");
                            setAddNewDay(false);
                            setDay("");
                            setInitialExercise();
                            setIsAdding(true);
                          }}
                        >
                          SAVE
                        </Button>
                        
</>
                      
                      }

                      <div className="form_btn">
                        <Button
                        style={{width:"200px"}}
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                          onClick={(e)=>{
                            e.preventDefault()
                            addTrainingPlanData()
                            setIsAdding(false);
                          
                          }
                          }
                        >
                          {
                           id?"Update":"Add"

                          }
                        </Button>
                      </div>
                    </CardContent>
                  </form>
                </CustomTabPanel>
              </Box>
            </Card>
            <Dialog open={editModal} onClose={()=>{
              setEditModal(false)
              setEditIndex(null);
              // setInitialExercise();

            }}  className="revert_dialog">

<DialogContent>
            <div  style={{paddingLeft:"10px"}}>
             <Card>
                <CardContent>
                  <Grid container spacing={2}  marginTop={"15px"}>
                              <Grid item lg={4} md={4} sm={6} xs={12} marginBlock={"5px"}>
                              <Typography paddingBottom={"15px"}>
                              Edit Exercises of {trainingDays[editIndex]?.day}
                                </Typography>
                              </Grid>
        
                              </Grid>
                              <Grid container spacing={2}>
                              {
                                    exerciseArray?.map((data:any)=>{
                                      return (
                                     <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <FormControlLabel sx={{color:"black"}} style={{color:"black"}} label={data.exerciseName} control={<Checkbox checked={data.isChecked} 
                                        onChange={(e)=>setExcerciseArray((prevData:any)=>{
                                         return prevData?.map((data2:any)=>{
                                            if(data2._id==data._id)
                                              {
                                                return {...data2,isChecked:!data2.isChecked}
                                              }
                                              return data2;
                                          })
                                        })} />}/>
                                    </Grid>
                                    )
                                    })
                              } 
                          </Grid>
                          <Button
                         sx={{marginTop:"35px"}}
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                          onClick={()=>{
                            setEditModal(false);
                            setTrainingDays((prevData:any)=>{
                             return prevData.map((data:any,index:any)=>{
                                if(editIndex==index)
                                { 
                                console.log("000000000000000",exerciseArray.filter((data:any)=>data.isChecked));
                                  return {day:data.day,exercises:exerciseArray.filter((data:any)=>data.isChecked).map((data:any)=>{
                                    return {exerciseId:data._id||data.exerciseId}
                                  })}
                                }
                                return data;
                              })
                            })
                          }}
                          >
                            SAVE
                          </Button>
                </CardContent>
             </Card>
          </div>

          </DialogContent>
            </Dialog>
            <WarnModal
          setOpen={setOpen}
          open={open}
          name={"day"|| ""}
          handleDelete={() => {
            setTrainingDays((prevData:any)=>prevData.filter((item:any)=>item.day !== selectedId))
          }}
        />
          </div>
  
      </>
    );
  };
  
   
  
  export default AddTrainingPlan;