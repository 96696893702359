import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import EditText from "../../components/EditText";
import { Delete } from "@mui/icons-material";
import { showError, showToast } from "../../constants/toast";
import Loader from "../../helpers/Loader";
import { UploadMedia, UploadVideo } from "../../utils/uploadMedia";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useAddexerciseMutation, useEditexerciseByIdMutation, useLazyGetexercisesByIdQuery } from "../../services/exercise";
import { isNumber } from "../../utils/validations";

const AddExercises = () => {
  const navigate = useNavigate();
  const {id}=useParams();
  const [category, setCategory] = useState<string>("");
  const[subCategory,setSubCategory]=useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [fileName, setFileName] = useState<string>("");
  const [error,setError]=useState(false);
  const [image,setImage]=useState<string>("");
  const[videoLink,setVideoLink]=useState<string>("");
  const[exerciseName,setExerciseName]=useState<string>("")
  const[rep,setRep]=useState("");
  const[weight,setWeight]=useState("");
  const[restTime,setRestTime]=useState("");
  const[sets,setSets]=useState<any>([]);
  const [description, setDescription] = useState<string>("");
 const[isLoading,setIsLoading]=useState(false);
  const [addData]=useAddexerciseMutation();
  const[getDataById]=useLazyGetexercisesByIdQuery();
  const[updateData]=useEditexerciseByIdMutation();
  const[displayEditText,setDisplayEditText]=useState(true);
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("image")){
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts images.");
    }
  };
  useEffect(()=>{
    setSubCategory("");
  },[category])
  const CategoryData:any=[
    {
      categoryName:"Arms",
      subCategory:["Biceps","Triceps","Forearms"]
    },
    {
      categoryName:"Abs",
      subCategory:["Upper Abs","Lower Abs","Obliques"],
    },
    {
      categoryName:"Legs",
      subCategory:[]
    }
  ]
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(true);

    if(image=="")
      {
        showError("Image is required");
        return;
      }
      if(exerciseName=="")
        {
          showError("Exercise name is required");
          return;
        }
    const body={

        exerciseName: exerciseName,
        video: videoLink,
        image: image,
        repDetails:sets,
        description: description,
        // categoryName:category,
        // subCategoryName:subCategory
    }
    console.log(body);
    const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
   try{ 
    const response=(id ? await updateData({id:id,body:encryptedBody}).unwrap()  : await addData(encryptedBody).unwrap() );
    if(response.statusCode==200)
    {
      showToast(id?"Exercise details updated succesfully":"Exercise added successfully");
////        console.log("body",body);
navigate('/manage-exercises')
    }}
    catch(error:any)
    {
        showError(error.data.message);
    }
    // Handle form submission
  };

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const allowedExtensions = ["mp4"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    console.log("File extension:", fileExtension);
    if (!allowedExtensions.includes(fileExtension || "")) {
      setIsLoading(false);
      showError("Invalid file format: only .mp4 files are allowed");
      return;
    }
    setIsLoading(true);
    console.log("Starting file upload");
  
    const reader = new FileReader();
  
    reader.onloadend = async () => {
      try {
        const dataUrl = reader.result as string;
  
        if (!dataUrl.startsWith("data:") || !dataUrl.includes(",")) {
          throw new Error("Invalid data URL");
        }
  
        // Create a video element
        const video = document.createElement("video");
        video.src = dataUrl;
        video.muted = true;
        video.playsInline = true;
  
        // Wait for the video to load metadata
        await new Promise<void>((resolve, reject) => {
          video.onloadedmetadata = () => {
            resolve();
          };
          video.onerror = (error) => {
            reject(error);
          };
        });
  
        console.log("Video metadata loaded");
  
        // Set the current time to the middle of the video
        video.currentTime = video.duration / 2;
  
        // Wait for the video to seek to the middle frame
        await new Promise<void>((resolve, reject) => {
          video.onseeked = () => {
            resolve();
          };
          video.onerror = (error) => {
            reject(error);
          };
        });
  
        console.log("Video seeked to the middle frame");
  
        // Create a canvas element to capture the frame
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");
  
        if (!context) {
          throw new Error("Failed to get canvas context");
        }
  
        // Draw the frame on the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
        // Get the thumbnail data URL from the canvas
        const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
  
        console.log("Thumbnail captured");
  
        // Convert the thumbnail data URL to Blob
        const thumbnailBlob = await (await fetch(thumbnailDataUrl)).blob();
  
        // Upload the video
        const videoUploadResponse = await UploadVideo(file);
        const thumbnailUploadResponse = await UploadMedia(thumbnailBlob);
  
        if (videoUploadResponse?.statusCode === 200) {
          setVideoLink(videoUploadResponse?.data);
          // setThumbnail(thumbnailUploadResponse?.data)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          showError("Error uploading video or thumbnail");
        }
      } catch (error) {
        console.error("Error during file upload:", error);
        setIsLoading(false);
        showError("An error occurred during the upload process");
      }
    };
  
    // Read the file as a data URL
    reader.readAsDataURL(file);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if(!file)
     {showError("Invalid file")
       return}
       const allowedExtensions = ["png","jpg","jpeg","gif"];
          const fileExtension = file.name.split(".").pop()?.toLowerCase();
          console.log("sssssssssssssssssssss",fileExtension);
          if (!allowedExtensions.includes(fileExtension||"")) {
            setIsLoading(false);
            showError("Invalid file format: only png, jpg, gif images are allowed");
            return;
          }
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
    setIsLoading(false);

  };

  const getDataByIdData=async()=>{
    try{
    setDisplayEditText(false);
    const response=await getDataById({id:id}).unwrap();
    if(response.statusCode==200)
    {
    setVideoLink(response.data.video);
    setExerciseName(response.data.exerciseName);
    setSets(response.data.repDetails);
    setDescription(response.data.description);
    setImage(response.data.image);
    setCategory(response?.data?.categoryName);
    setSubCategory(response?.data?.subCategoryName);
    }
    }
    catch(error:any)
    {
    showError(error.data.message);
    }
    finally
    {
      setDisplayEditText(true);
    }

  } 

  useEffect(()=>{
    if(id)
    getDataByIdData();
  },[])
  return (
    <div>
        {/* <div className="main_layout">
          <div className="dashboard">
            <h3>Add Exercise</h3>
            <Button
                className="btn btn_primary"
              onClick={() => {
                navigate("/manage-exercises");
              }}
            >
              Back
            </Button>
          </div> */}
           <div className="main_loyout">
           <Loader isLoad={isLoading}/>
          <div className="dashboard">
            <h1 className="mn_hdng">Add Exercise</h1>
            <Button
              className="btn btn_primary"
              onClick={() =>               navigate("/manage-exercises")}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {image ? (
                      <div className="upload_image_preview3">
                        <CardMedia component="img" image={image} sx={ {border:"2px dotted black"}} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg,image/gif",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn3">
                            <img
                            style={{border:"1px dotted black"}}
                              src={
                                image
                                  ? image
                                  : "/static/images/placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">Image is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12} sx={{marginBottom:"30px"}}>
                      <Typography className="custom_label">
                         {"Upload Video"}
                      </Typography>
                      {videoLink ? (
                        <div className="upload_image_preview2">
                          <video controls width="100%" height="100%">
                            <source
                              src={videoLink}
                              type="video/mp4"
                            />
                          </video>
                          <CancelIcon
                            onClick={() =>
                              setVideoLink("")
                            }
                          />
                        </div>
                      ) : (
                        <Box className="upload_image_bnr">
                          <label htmlFor="icon-button-file-vdo">
                            <Input
                              sx={{ display: "none" }}
                              id="icon-button-file-vdo"
                              type="file"
                              inputProps={{
                                accept: "video/*",
                              }}
                              onChange={handleVideoUpload}
                            />
                            <Button
                              component="span"
                              className="upload_image_btn"
                            >
                              <AddIcon />
                            </Button>
                          </label>
                        </Box>
                      )}
                      {/* { error && videoLink==""? (
                        <h6 className="err_msg">Workout video is required</h6>
                      ) : (
                        ""
                      )} */}
                    </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                 
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Exercise Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      value={exerciseName}
                      onChange={(e)=>{
                        if(e.target.value.length<=100)
                          setExerciseName(e.target.value)
                        if(e.target.value.length>100)
                        showError("Excercise name can't exceed 100 characters")
                      }}
                      fullWidth
                      placeholder="Exercise Name"
                    />
                    {error && !exerciseName ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                        Category
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleCategoryChange}
                          displayEmpty
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {CategoryData.map((data:any,index:number)=>{
                            return <MenuItem value={data.categoryName}>{data.categoryName}</MenuItem>
                          })}
                         
                           
                        </Select>
                      </FormControl>
                    </Grid>
                    {category!=="Legs" && category!=="" && <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                       Sub-Category
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={subCategory}
                          onChange={(e)=>setSubCategory(e.target.value)}
                          displayEmpty
                          disabled={category=="Legs"}
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {CategoryData.map((data:any)=>{
                            if(data.categoryName==category)
                            {
                              return data.subCategory.map((data:any)=>{
                                return <MenuItem value={data}>{data}</MenuItem>
                              })
                            }
                          })}
                           
                           
                        </Select>
                      </FormControl>
                    </Grid>} */}
                    </Grid>
                    <Grid container spacing={2} marginTop={"15px"}>
              
                   <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Number of Reps
                      </Typography>
                      <TextField
                      value={rep}
                      onChange={(e)=>setRep(e.target.value)}
                        hiddenLabel
                        type={"text"}
                        name="title"
                        variant="outlined"
                        fullWidth
                        placeholder="Rep Count"
                      />
                   </Grid>
                  
                   <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Weight per Rep
                      </Typography>
                      <TextField
                      value={weight}
                      onChange={(e)=>setWeight(e.target.value)}
                      hiddenLabel
                        type={"text"}
                        name="title"
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Weight"
                      />
                   </Grid>
                   <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                       Rest Time (in Seconds)
                      </Typography>
                      <TextField
                      value={restTime}
                      onChange={(e)=>{
                        if(isNumber(e.target.value))
                        setRestTime(e.target.value)}}
                        hiddenLabel
                        type={"text"}
                        name="title"
                        variant="outlined"
                        fullWidth
                        placeholder="Rest Time"
                      />
                   </Grid>
                   <div className="form_btn" style={{ display:"flex",alignItems:"end",marginInline:"15px"}}>
                     <Button
                      size="large"
                      type="button"
                      className="btn btn_primary"
                      onClick={()=>{
                        if(rep=="")
                        {
                          showError("Rep is required")
                          return;
                        }
                        if(weight=="")
                          {
                            showError("Weight is required")
                            return;
                          }
                          if(restTime=="")
                            {
                              showError("Rest time is required")
                              return;
                            }
                            if(rep.length>8 || weight.length>8 ||restTime.length>8)
                              {
                                showError("Invalid Set");
                                return;
                              }
                        setSets((prevData:any)=>{
                          return [...prevData,{rep,weight,restTime}]
                          

                        });
                        setRep("");
                        setWeight("");
                        setRestTime("");
                      }}
                    >
                      Add Set
                     </Button>
                   </div>
                    </Grid>
                    <TableContainer className="table_container" >
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 1130,maxWidth:950 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Number of Rep</TableCell>
                    <TableCell>Weight</TableCell>
                    <TableCell>Rest Time</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sets.length==0?
                  <TableRow>
      <TableCell colSpan={5} align="center">No Sets Added</TableCell>
      </TableRow>
      :(sets.map((data:any,index:any)=>{
return<TableRow>
<TableCell align="center">{index+1}</TableCell>
<TableCell>{data.rep}</TableCell>
<TableCell>{data.weight}</TableCell>
<TableCell>{data.restTime}</TableCell>

<TableCell>
  <Box className="table_actions">
    <IconButton onClick={()=>{
      setSets((prevData:any)=>prevData.filter((_:any,id:number)=>id!==index))
    }}>
      <Delete />
    </IconButton>
  </Box>
</TableCell>
  </TableRow>
                  }) 
                 
                  )}
                </TableBody>
              </Table>
            </TableContainer>
                   
                      {/* Add the description text field below the video upload */}
                
                
                
                <Grid container spacing={2} marginTop={"15px"}>
                     
                      <Grid item lg={9} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    { displayEditText && <EditText
                      content={description}
                      setContent={setDescription}
                />}
                  </Grid>
                
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
    </div>
  );
};

export default AddExercises;
