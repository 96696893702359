import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import Parser from 'html-react-parser';

import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add, Visibility } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import { useLazyGetUsersQuery, useEditUserByIdMutation, useDeleteUserByIdMutation, useLazyGetUserCSVQuery } from "../../services/user";
import { UserResponse } from "../../types/User";
import { useDeleteFastingByIdMutation, useEditFastingByIdMutation, useLazyGetFastingQuery } from "../../services/fasting";
import { isValidInput } from "../../utils/validations";
import { FastingResponse } from "../../types/fasting";
import { Pagination } from "../../components";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import users from "../users";
import { CommonBody } from "../../types/General";
import { handleDelete } from "../../utils/commonFunctions";
import WarnModal from "../../components/modals/WarnModal";
import Loader from "../../helpers/Loader";
const ManageFasting = () => {

  const user = useAuth();
  const[isLoading,setIsLoading]=useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [fasting, setFasting] = useState<any>([]);
//   [{
//     _id: "23232",
//     fullName: "John Doe", // Optional
//     phone: "+1 123-456-7890",
//     image: "https://example.com/profile_image.jpg",
//     isBlocked: false,
//     countryCode: "US",
//     email: "john.doe@example.com",
//     userName: "johndoe", // Optional
//     address: "123 Main St, City, State, Zip"
// }]
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getFastings /*, { isLoading }*/] = useLazyGetFastingQuery();
  const [updateFastingStatus]=useEditFastingByIdMutation();
  const[deleteById]=useDeleteFastingByIdMutation();
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getFastingsList = async () => {
    setIsLoading(true);
    try {
      const response = await getFastings({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setFasting(response?.data?.fasting || []);
        setTotalCount(response?.data?.count);
      } else {
        setFasting([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

 
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        isBlocked:!fasting[index]?.isBlocked
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
      const response = await updateFastingStatus({ id: `${fasting[index]._id}` , body:encryptedData}).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setFasting((prevData:any)=>{
        let temp=[...prevData];
        temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getFastingsList();
  }, [debouncedSearchTerm, page]);
  console.log("FASTING",fasting);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);




  const label = { inputProps: { "aria-label": "Switch demo" } };




  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];
  

  return (
   
      <div className="main_loyout">
        <Loader isLoad={isLoading}/>

        <div className="dashboard">
          <h1 className="mn_hdng"> Manage Fasting</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {(user?.role==1)||(user?.permissions?.[4]?.isEdit)?<Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-fasting/add")}
              >
                {<Add/>}Add Fasting
              </Button>:null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                   
                  {/* <TableCell>Title</TableCell> */}
                    <TableCell>Title</TableCell>
                    {/* <TableCell>Description</TableCell> */}
                 
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fasting.length?(fasting.map((row:FastingResponse, i:number) => (
                  <TableRow>
                    <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                    <TableCell>
                        <figure className="user_img">
                          <img src={row.image?row.image:"/static/images/fasting-placeholder.png"} alt="" />
                        </figure>
                      </TableCell>
                    {/* <TableCell>Body Scan</TableCell> */}
                    <TableCell>{row.title}</TableCell>
                    {/* <TableCell>{row?.description && Parser(row?.description)}</TableCell> */}

                    
                    

                    <TableCell align="center">
                      <Switch {...label} 
                     checked={!row.isBlocked} 
                     size="small" 
                     disabled={!((user?.role==1)||(user?.role==2 && user?.permissions?.[4].isEdit))}

                      onChange={()=>handleStatusChange(i)}
  
                        />
</TableCell>

                    <TableCell>
                     
                     
                      <Box className="table_actions">
                      <IconButton     onClick={() => navigate("/manage-fasting/details/"+row._id)}>
                          <VisibilityIcon />
                        </IconButton>
                       { (user?.role==1)||(user?.permissions?.[4]?.isEdit)?<IconButton
                          onClick={() => navigate("/manage-fasting/edit/"+row._id)}
                        >
                          <ModeEditIcon />
                        </IconButton>:null}
                       {(user?.role==1)||(user?.permissions?.[4]?.isDelete)? <IconButton onClick={() =>{
                          setSelectedId(row._id);
                          setOpen(true);
                        }}>
                          <DeleteIcon />
                        </IconButton>:null}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))):<TableRow>
                <TableCell colSpan={5} sx={{textAlign:"center"}}>
                  No Fasting Found</TableCell></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={fasting}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
         <WarnModal
        setOpen={setOpen}
        open={open}
        name={"fasting"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getFastingsList)}
      />
      </div>
 
  );
};

export default ManageFasting;
